import React, { useEffect, useState, useRef, useContext } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import eventBus from "../../untils/event";
import { Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import "./unity.scss";
import { useNavigate } from 'react-router-dom';

const UnityContainer = (props: any) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState("中");
  const curentLan = useRef('中')
  const [isFullscreen, setIsFullscreen] = useState(false);
  const navigate = useNavigate();
  const canBack = props.canBack;
  const searchParams = new URLSearchParams(window.location.search); // 获取查询字符串
  const sceneName: any = searchParams.get("scene") || 'qiangxiu'; // 获取参数 'id'

  const specialList: any = ["nuo",'yunjin','wushi']
  const isMobile = () => {
    // 判断是否为移动设备
    return (
      typeof window.orientation !== "undefined" || // 判断是否存在window.orientation属性，此属性在移动设备上一般存在
      navigator.userAgent.indexOf("IEMobile") !== -1 || // 判断是否为Windows Phone
      navigator.userAgent.indexOf("iPhone") !== -1 || // 判断是否为iPhone
      (navigator.userAgent.indexOf("Android") !== -1 &&
        navigator.userAgent.indexOf("Mobile") !== -1) || // 判断是否为Android手机
      navigator.userAgent.indexOf("BlackBerry") !== -1 || // 判断是否为BlackBerry
      navigator.userAgent.indexOf("Opera Mini") !== -1 // 判断是否为Opera Mini浏览器
    );
  };

  const isMobileSepcial = () => {
    return specialList.indexOf(sceneName) >= 0 && isMobile()
  }

  useEffect(() => {
    if (localStorage.getItem("language")) {
      changeLanguage(localStorage.getItem("language") || "");
    }
  }, []);

  const goAnyWhere = (url: string) => {
    navigate(url);
  }

  let unityContext = null

  if (isMobileSepcial()) {
    unityContext = new UnityContext({
      loaderUrl: `https://oss.tnworld.cn/kunlun/WebScene/${props.version}/${props.sendUnity}/Build/${props.sendUnity}.loader.js`,
      dataUrl: `https://oss.tnworld.cn/kunlun/WebScene/${props.version}/${props.sendUnity}/Build/${props.sendUnity}.data.unityweb`,
      frameworkUrl:
        `https://oss.tnworld.cn/kunlun/WebScene/${props.version}/${props.sendUnity}/Build/${props.sendUnity}.framework.js.unityweb`,
      codeUrl: `https://oss.tnworld.cn/kunlun/WebScene/${props.version}/${props.sendUnity}/Build/${props.sendUnity}.wasm.unityweb`,
      streamingAssetsUrl: `https://oss.tnworld.cn/kunlun/WebScene/${props.version}/${props.sendUnity}/StreamingAssets`,
    });
  } else {
    unityContext = new UnityContext({
      loaderUrl: "https://oss.tnworld.cn/kunlun/WebScene/" + props.version + "/verse/Build/verse.loader.js",
      dataUrl: "https://oss.tnworld.cn/kunlun/WebScene/" + props.version + "/verse/Build/verse.data.unityweb",
      frameworkUrl:
        "https://oss.tnworld.cn/kunlun/WebScene/" + props.version + "/verse/Build/verse.framework.js.unityweb",
      codeUrl: "https://oss.tnworld.cn/kunlun/WebScene/" + props.version + "/verse/Build/verse.wasm.unityweb",
      streamingAssetsUrl: "https://oss.tnworld.cn/kunlun/WebScene/" + props.version + "/verse/StreamingAssets",
    });
  }



  const fullScreen = () => {
    if (!isFullscreen) {
      document.documentElement.requestFullscreen()
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).mozCancelFullScreen) { /* Firefox */
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitExitFullscreen) { /* Chrome, Safari and Opera */
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) { /* IE/Edge */
        (document as any).msExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  // 修改语言
  const changeLanguage = (lan: string) => {
    localStorage.setItem("language", lan);
    window.eventBus.emit("changeLanguage", lan);
    if (lan === "CN") {
      setLanguage("中");
      curentLan.current = '中';
      i18n.changeLanguage("ch");
    } else {
      setLanguage("EN");
      curentLan.current = 'EN';
      i18n.changeLanguage("en");
    }
  };

  const clickLan = () => {
    if (curentLan.current === '中') {
      changeLanguage("EN");
    } else {
      changeLanguage("CN");
    }
  }

  // 用来处理unity的回调
  useEffect(() => {      
    if (isMobileSepcial()) {
      unityContext.on("progress", (e)=> {
        console.log("jason", e)
        window.eventBus.emit("singleLoadProgress", e)
      })
    }   

    
    window.MainSceneInitComplete = () => {
      if (isMobileSepcial()) return
      // 资源加载完毕后切换场景 给定版本号
      unityContext.send(
        "GameInitate",
        "SetCDNPath",
        "https://oss.tnworld.cn/kunlun/WebScene" + "/" + props.version
      );
    };

    //主场景加载完成
    window.AddressableInitComplete = () => {
      if (isMobileSepcial()) return
      
      if (sceneName === 'nuo' && isMobile()) {
        unityContext.send("GameInitate", "LoadScene", 'nuo_mobile');
      } else {
        unityContext.send("GameInitate", "LoadScene", props.sendUnity);
      }
    };

    window.TransferMobile = () => {
      console.log('??????????mobile_nuo',window.location.host)
      window.location.replace('/scene?scene=nuo_inside');
    };
 
    // 输入时候不让unity捕获键盘事件
    window.eventBus.on("switchInput", (str: string) => {
      unityContext.send("GameInitate", "SwitchInput", str);
    });

    // 切换语言
    window.eventBus.on("changeLanguage", (str: string) => {
      unityContext.send("GameInitate", "SetLanguage", str);
    });

    // 加载成功
    window.eventBus.on("loadSuccess", (str: string) => {
      unityContext.send(
        "GameInitate",
        "SetDevice",
        isMobile() ? "MOBILE" : "PC"
      );
    });
  }, []);

  return (
    <>
      <Unity
        style={{ width: "100vw", height: "100vh" }}
        unityContext={unityContext}
      />
      {canBack &&
        <div className="back-home" onClick={() => goAnyWhere('/')}>
          <img src="/assets/images/Icon.png" alt="" />
          {i18n.t('scene_back_home')}
        </div>
      }

      <div className="fullscreen-button" onClick={() => fullScreen()}>
        {!isFullscreen && <img src="/assets/images/expand-simple.png" alt="" />}
        {isFullscreen && <img src="/assets/images/collapse-simple.png" alt="" />}
      </div>
      <div className="language-change" onClick={clickLan}>
        {language}
      </div>
    </>
  );
};

export default UnityContainer;
