import React, { useState, useEffect, useRef } from 'react';
import VoiceRecognition from '../../components/voiceRecognition/VoiceRecognition';
import SpeakChat from '../../components/speakChat/SpeakChat';

const AudioLine: React.FC = () => {

  return (
    <>
      <div id="app" style={{ width: '100vw', height: '100vh', overflow: 'hidden', position: 'relative' }}>
            <SpeakChat/>
      </div>
    </>
  );
};

export default AudioLine;
