// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import zh from './zh.json';

// 语言资源
const resources = {
  en: {
    translation: en
  },
  zh: {
    translation: zh
  }
};

// 初始化i18next
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng:localStorage.getItem('language')&&localStorage.getItem('language')==='EN'?'en': 'zh', // 默认语言
    fallbackLng: 'zh', // 回退语言
    interpolation: {
      escapeValue: false // React已经默认进行转义
    }
  });

export default i18n;
