import React, { useState, useEffect, useRef } from 'react';
import MessageDialog from '../../components/dialog/MessageDialog'
import Chat from '../../components/chat/Chat'
import { useTranslation } from 'react-i18next';
import Loading from '../../components/load/Loading';
import UnityContainer from '../../components/webgl/UnityContainer';
import data from '../../untils/data.json';
import { useNavigate } from 'react-router-dom';

const SceneNew: React.FC = () => {
    const { i18n } = useTranslation();
    const [showDialog, setShowDialog] = useState(false);//弹窗
    const [showChat, setSHowChat] = useState(false);//npc chat
    const [showLoading, setShowLoading] = useState(true);//进度条
    const [dialogId, setDialogId] = useState(0); //弹窗id
    const searchParams = new URLSearchParams(window.location.search); // 获取查询字符串
    const sceneName: any = searchParams.get('scene') // 获取参数 'id'
    let dialogData: any = data;//弹窗数据
    dialogData = dialogData?.[sceneName]||null
    const navigate = useNavigate();

    const [sceneId, setSceneId] = useState(dialogData?.id); // 场景Id
    const [npcId, setNpcId] = useState(dialogData?.npc||0);   // npc Id
    const dialogArr = useRef<any>([]);//用来存储弹窗id数组
    const dialogRef = useRef<boolean>(false);// 实时判断此时弹窗是打开还是关闭
    let historyList: any = [];//历史弹窗列表

    // 场景交互事件处理
    useEffect(() => {
        if(!sceneName || !dialogData){
            navigate('/home');
            return;
        }
        // 设置活动标题
        document.title = i18n.t(`scene_${sceneId}_name`);

        window.eventBus.on('loading-complete',()=>{
            setShowLoading(false);
        })

        // 打开弹窗
        window.OpenUI = (data: any) => {
            console.log(data,'???????openUi')
            // 弹窗列表是否已存在当前弹窗 该弹窗是否只打开一次且已经打开过了
            if (!dialogArr.current?.includes(data) && !(dialogData.id?.['diglog-' + data]?.openOnce && historyList?.includes(data))) {
                historyList.push(data);
                let temp = JSON.parse(JSON.stringify(dialogArr.current)) || [];
                temp.push(data);
                dialogArr.current = temp;
                // 当前是否有弹窗正在打开
                if (!dialogRef.current) {
                    setDialogId(data);
                    dialogRef.current = true;
                    setShowDialog(true);
                }
            }
        }

        // 打开npc聊天
        window.OpenNPC = (data: any) => {
            console.log('??????npc',data);
            window.eventBus.emit("switchInput",'INPUT');
            setNpcId(data);
            setSHowChat(true);
        }

        // 打开BGM
        window.OpenBGM = (e: any) => {}

        // 关闭BGM
        window.CloseBGM = (e: any) => {}

        // 关闭弹窗
        window.eventBus.on("closeDialog", () => {
            setShowDialog(false);
            // 关闭当前弹窗后 删除当前弹窗后接着去判断弹窗列表是否还有要打开的
            let temp = JSON.parse(JSON.stringify(dialogArr.current)) || [];
            temp.shift();
            dialogArr.current = temp || [];
            if (dialogArr.current?.length > 0) {
                setDialogId(dialogArr.current[0]);
                setTimeout(() => {
                    setShowDialog(true);
                }, 200)
            } else {
                dialogRef.current = false;
            }
        })

        // 关闭npc聊天
        window.eventBus.on("closeNpcChat", () => {
            window.eventBus.emit("switchInput",'UNITY');
            setSHowChat(false);
        })
        return () => {
            window.eventBus.off("closeDialog", () => {
                setShowDialog(false);
                // 关闭当前弹窗后 删除当前弹窗后接着去判断弹窗列表是否还有要打开的
                let temp = JSON.parse(JSON.stringify(dialogArr.current)) || [];
                temp.shift();
                dialogArr.current = temp || [];
                if (dialogArr.current?.length > 0) {
                    setDialogId(dialogArr.current[0]);
                    setTimeout(() => {
                        setShowDialog(true);
                    }, 200)
                } else {
                    dialogRef.current = false;
                }
            })

            window.eventBus.off("closeNpcChat", (data: any) => {
                setSHowChat(false);
            })
        };
    }, []);

    return (
        <>
            <UnityContainer version={'0.1.x'} />
            {showLoading && <Loading />}
            {showChat && <Chat sceneId={sceneId} npcId = {npcId} />}
            {showDialog && <MessageDialog showDialog={showDialog} sceneId={sceneId} dialogData={dialogData} dialogId={dialogId} />}
        </>
    )
};

export default SceneNew;