import React, { useState, useEffect } from 'react';
import './partThree.scss';
import ImgDocument from '../../../components/img/ImgDocument';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { zipImages } from '../../../untils/until';
import { ClassNames } from '@emotion/react';
interface PartThreeProps {
    scrollPosition: number; // 新增属性用于接收滚动位置
    mobile: boolean;//是否是移动端
}
const PartThree: React.FC<PartThreeProps> = ({ scrollPosition, mobile }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // pc图片数组
    const imgArr = [
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/home/p2a山左后.png',
            top: '-20%',
            direction: 'X',
            speed: 0.1,
            zIndex: 1,
            num: 2,
            left: '-50%',
            maxScroll: 1.8 * window.innerHeight,
            float: 'down'
        },
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/home/p2a山左前.png',
            top: '-20%',
            direction: 'X',
            speed: 0.1,
            zIndex: 2,
            num: 2,
            left: '-50%',
            maxScroll: 1.8 * window.innerHeight,
            float: 'down'
        },
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/home/山右后.png',
            top: '-0%',
            direction: 'X',
            speed: 0.1,
            zIndex: 4,
            num: 2.5,
            left: '40%',
            maxScroll: 2.3 * window.innerHeight,
            float: 'down'
        },
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/home/山右中.png',
            top: '0%',
            direction: 'X',
            speed: 0.1,
            zIndex: 5,
            num: 2.5,
            left: '40%',
            maxScroll: 2.3 * window.innerHeight,
        },
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/home/山右前.png',
            top: '0%',
            direction: 'X',
            speed: 0.1,
            zIndex: 6,
            num: 2.5,
            left: '40%',
            maxScroll: 2.3 * window.innerHeight,
        }];
    // mobile图片数组
    const mobileImgArr = [
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/p2a山左后.png',
            top: '-20%',
            direction: 'X',
            speed: 0.1,
            zIndex: 1,
            num: 2,
            left: '-50%',
            maxScroll: 1.8 * window.innerHeight,
            float: 'down'
        },
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/p2a山左前.png',
            top: '-20%',
            direction: 'X',
            speed: 0.1,
            zIndex: 2,
            num: 2,
            left: '-50%',
            maxScroll: 1.8 * window.innerHeight,
            float: 'down'
        },
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/p3山右后.png',
            top: '32%',
            direction: 'X',
            speed: 0.1,
            zIndex: 1,
            num: 2.5,
            left: '40%',
            maxScroll: 1.9 * window.innerHeight,
            className:"hill-middle"
        },
      
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/p3山右中.png',
            top: '32%',
            direction: 'X',
            speed: 0.1,
            zIndex: 5,
            num: 2.5,
            left: '40%',
            maxScroll: 1.9 * window.innerHeight,
            className:"hill-middle"
        },
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/p3山右前.png',
            top: '32%',
            direction: 'X',
            speed: 0.1,
            zIndex: 6,
            num: 2.5,
            left: '40%',
            maxScroll: 1.9 * window.innerHeight,
            className:"hill-middle"
        },];

    // 跳转
    const goAnyWhere = (url: string) => {
        navigate(url);
    }

    return (
        <>
            {!mobile &&
                <div className="part-3">
                    <div className='part3-title'>
                        Praying for Peace in Nuo
                    </div>
                    <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/LaunchpadImage.png")} style={{ zIndex: 3 }} alt="" />
                    <img className='breach-text' style={{ zIndex: 7 }} src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/装饰文字.png")} alt="" />
                    <img className='breach-star' style={{ zIndex: 8 }} src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/星星.png")} alt="" />
                    <div className='opcity-mask' onClick={() => window.open('https://traditionow.xyz/Launchpad/RWA/Praying')}>

                    </div>
                    <div className='visit-btn' onClick={() => window.open('https://traditionow.xyz/Launchpad/RWA/Praying')}>
                        {t('home_visit')}
                    </div>
                    {
                        imgArr.map((item: any, index: any) => {
                            return (<ImgDocument key={index} data={item} scrollPosition={scrollPosition} />)
                        })
                    }
                </div>}
            {mobile &&
                <div className="part-3">
                     <div className='part3-title'>
                        Praying for Peace in Nuo
                    </div>
                    <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/LaunchpadImage2.png")} style={{ zIndex: 3 }} alt="" />
                    <img className='breach-text' style={{ zIndex: 7 }} src={zipImages("https://oss.tnworld.cn/kunlun/assets/mobile-home/p3文字.png")} alt="" />
                    <img className='breach-star' style={{ zIndex: 8 }} src={zipImages("https://oss.tnworld.cn/kunlun/assets/mobile-home/p3星星.png")} alt="" />
                    {/* <img className='breach-star' style={{ zIndex: 4 }} src={zipImages("https://oss.tnworld.cn/kunlun/assets/mobile-home/p3山右后.png")} alt="" /> */}
                    <div className='opcity-mask'onClick={() => window.open('https://traditionow.xyz/Launchpad/RWA/Praying')}>

                    </div>
                    <div className='visit-btn'onClick={() => window.open('https://traditionow.xyz/Launchpad/RWA/Praying')}>
                        {t('home_visit')}
                    </div>
                    {
                        mobileImgArr.map((item: any, index: any) => {
                            return (<ImgDocument key={index} data={item} scrollPosition={scrollPosition} />)
                        })
                    }
                </div>}
        </>
    )
};

export default PartThree;