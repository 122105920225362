import React, { useEffect, useRef } from 'react';
import p5 from 'p5';

class Particle {
  x: number;
  y: number;
  vx: number;
  vy: number;
  alpha: number;

  constructor(p5: p5) {
    this.x = p5.width / 2;
    this.y = p5.height / 2;
    this.vx = p5.random(-5, 5);
    this.vy = p5.random(-5, 5);
    this.alpha = 0;
  }

  finished(): boolean {
    return this.alpha < 0;
  }

  update(p5: p5) {
    this.x += this.vx;
    this.y += this.vy;
    this.alpha += 5;
    p5.frameRate(100);
  }

  show(p5: p5) {
    p5.noStroke();
    p5.fill(220, 240, p5.random(100,255), this.alpha);
    p5.ellipse(this.x, this.y, 5);
  }
}


const Particles: React.FC = () => {
  const sketchRef = useRef<HTMLDivElement>(null);
  const flag = useRef(false);

  useEffect(() => {
    if(flag.current) return
    flag.current = true;

    const particles: Particle[] = []; 
    new p5((p5: p5) => {
      p5.setup = () => {
        p5.createCanvas(p5.windowWidth, p5.windowHeight);
      };

      p5.draw = () => {
        p5.background(5, 3, 8);
        for (let i = 0; i < 5; i++) {
          particles.push(new Particle(p5));
        }

        for (let i = particles.length - 1; i >= 0; i--) {
          particles[i].update(p5);
          particles[i].show(p5);
          if (particles[i].finished()) {
            particles.splice(i, 1);
          }
        }
      };

    //   p5.keyPressed = () => {
    //     if (p5.key === 's' || p5.key === 'S') p5.saveCanvas('myCanvas', 'jpg');
    //   };
    }, sketchRef.current!);

    return () => {
      // Cleanup p5 instance when component unmounts
    };
  }, []);

  return <div ref={sketchRef}></div>;
};

export default Particles;
