import React, { useState, useEffect, useRef } from "react";
import './imgDocument.scss';
import { zipImages } from "../../untils/until";

const ImgDocument = (props: any) => {
    const [offset, setOffset] = useState(0);
    const data: any = props.data;
    const postion: any = props.scrollPosition;

    useEffect(() => {
        const scrollPosition = postion
        const maxScroll = props.data.maxScroll || 0.8 * window.innerHeight;
        const scrollPercentage = scrollPosition <= maxScroll ? (scrollPosition / maxScroll) * 100 : 100;
        // Set offset based on scroll percentage
        setOffset(data?.float === 'down' ? scrollPercentage / props.data.num : -scrollPercentage / props.data.num);
    }, [props]);

    return (
        <img src={zipImages(data.src)}
            alt=""
            className={`home-img ${data.className || ""}`}
            style={{
                transform: `translate${data.direction}(${offset}%)`,
                transition: `transform ${data.speed}s ease`,
                top: data.top,
                zIndex: data.zIndex,
                left: data?.left || 0
            }}
        />
    )
}

export default ImgDocument;