// Carousel.tsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './carousel.scss';
import { useTranslation } from 'react-i18next';
import config from '../../config';

interface CarouselItem {
    id: number;
    pos: number;
    src: string
}const Carousel: React.FC = () => {
    const { i18n } = useTranslation();
    const [items, setItems] = useState<CarouselItem[]>([
        { id: 1, pos: -2, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu3.webp' },
        { id: 2, pos: -1, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu2.webp' },
        { id: 3, pos: 0, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu1.webp' },
        { id: 4, pos: 1, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu5.webp' },
        { id: 5, pos: 2, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu4.webp' },
    ]);
    const calArr = useRef<any>(items);//存储实时改变的数组
    const navigate = useNavigate();
    const currentScene = useRef('qiangxiu');//场景名字
    const newImages1 = [
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu0.webp',
            text: config.projects.indexOf("qiangxiu") < 0 ? 'coming soon' : null
        },
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/nuo/nuo0.webp',
            text: config.projects.indexOf("nuo") < 0 ? 'coming soon' : null
        },
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/yunjin/yunjin0.webp',
            text: config.projects.indexOf("yunjin") < 0 ? 'coming soon' : null
        },
        {
            src: 'https://oss.tnworld.cn/kunlun/assets/xingshi/xingshi0.webp',
            text: config.projects.indexOf("wushi") < 0 ? 'coming soon' : null
        },
    ];
    const timer = useRef<any>(null);
    const [sceneName, setSceneName] = useState('qiang');
    const [chooseScene, setChooseScene] = useState(0);
    const [isMoving, setIsMoving] = useState(false);//是否在播放

    const changeSwiper = (index: any) => {
        switch (index) {
            case 0:
                if (config.projects.indexOf("qiangxiu") < 0) break;
                setChooseScene(index);
                setSceneName('qiang');
                currentScene.current = 'qiangxiu';
                calArr.current = [
                    { id: 1, pos: -2, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu3.webp' },
                    { id: 2, pos: -1, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu2.webp' },
                    { id: 3, pos: 0, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu1.webp' },
                    { id: 4, pos: 1, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu5.webp' },
                    { id: 5, pos: 2, src: 'https://oss.tnworld.cn/kunlun/assets/xiu/xiu4.webp' },
                ];
                setItems(calArr.current);
                break;
            case 1:
                if (config.projects.indexOf("nuo") < 0) break;
                setChooseScene(index);
                setSceneName('nuo');
                currentScene.current = 'nuo';
                calArr.current = [
                    { id: 1, pos: -2, src: 'https://oss.tnworld.cn/kunlun/assets/nuo/nuo3.webp' },
                    { id: 2, pos: -1, src: 'https://oss.tnworld.cn/kunlun/assets/nuo/nuo2.webp' },
                    { id: 3, pos: 0, src: 'https://oss.tnworld.cn/kunlun/assets/nuo/nuo1.webp' },
                    { id: 4, pos: 1, src: 'https://oss.tnworld.cn/kunlun/assets/nuo/nuo5.webp' },
                    { id: 5, pos: 2, src: 'https://oss.tnworld.cn/kunlun/assets/nuo/nuo4.webp' },
                ];
                setItems(calArr.current);
                break;
            case 2:
                if (config.projects.indexOf("yunjin") < 0) break;
                setChooseScene(index);
                setSceneName('yun');
                currentScene.current = 'yunjin';
                calArr.current = [
                    { id: 1, pos: -2, src: 'https://oss.tnworld.cn/kunlun/assets/yunjin/yunjin3.webp' },
                    { id: 2, pos: -1, src: 'https://oss.tnworld.cn/kunlun/assets/yunjin/yunjin2.webp' },
                    { id: 3, pos: 0, src: 'https://oss.tnworld.cn/kunlun/assets/yunjin/yunjin1.webp' },
                    { id: 4, pos: 1, src: 'https://oss.tnworld.cn/kunlun/assets/yunjin/yunjin5.webp' },
                    { id: 5, pos: 2, src: 'https://oss.tnworld.cn/kunlun/assets/yunjin/yunjin4.webp' },
                ];
                setItems(calArr.current);
                break;
            case 3:
                if (config.projects.indexOf("wushi") < 0) break;
                setChooseScene(index);
                setSceneName('shi');
                currentScene.current = 'wushi';
                calArr.current = [
                    { id: 1, pos: -2, src: 'https://oss.tnworld.cn/kunlun/assets/xingshi/xingshi3.webp' },
                    { id: 2, pos: -1, src: 'https://oss.tnworld.cn/kunlun/assets/xingshi/xingshi2.webp' },
                    { id: 3, pos: 0, src: 'https://oss.tnworld.cn/kunlun/assets/xingshi/xingshi1.webp' },
                    { id: 4, pos: 1, src: 'https://oss.tnworld.cn/kunlun/assets/xingshi/xingshi5.webp' },
                    { id: 5, pos: 2, src: 'https://oss.tnworld.cn/kunlun/assets/xingshi/xingshi4.webp' },
                ];
                setItems(calArr.current);
                break;
        }
    }

    const update = (newActiveId: number, pos?: number) => {
        const newActivePos = pos || calArr.current.find((item: any) => item.id === newActiveId)?.pos;
        if (newActivePos === undefined) return;
        setTimeout(() => {
            setIsMoving(false);
        }, 800)
        const updatedItems = calArr.current.map((item: any) => ({
            ...item,
            pos: getPos(item.pos, newActivePos),
        }));
        calArr.current = updatedItems;
        setItems(updatedItems);
    };

    // 点击切换
    const handleClick = (index: any) => {
        if (index === 0) {
            goAnyWhere();
        }
    };

    // 跳转
    const goAnyWhere = () => {
        navigate('/scene?scene=' + currentScene.current);
    }
    const getPos = (current: number, active: number): number => {
        const diff = current - active;

        if (Math.abs(diff) > 2) {
            return -current;
        }

        return diff;
    };

    useEffect(() => {
        timer.current = setInterval(() => {
            setIsMoving(true);
            setTimeout(() => {
                update(0, -1);
            }, 200)
        }, 2800);

        return () => {
            clearInterval(timer.current);
            timer.current = null;
        }
    }, [])

    return (
        <div className="carousel">
            <div className='part-2-title'>
                {i18n.t('home_kunlun_metaverse')}
            </div>
            <div className='row-imgs'>
                {newImages1.map((item: any, index: any) => (
                    <div onClick={() => changeSwiper(index)} className={`row-item ${index === chooseScene ? 'active-item' : 'unactive-item'}`} key={index}>
                        <img src={item.src} className='row-img-item' />
                        {item.text && <div className='comming'><div>{item.text}</div></div>}
                    </div>
                ))}
            </div>
            <div className='row-icons'>
                {newImages1.map((item: any, index: any) => (
                    <div key={index}>
                        <img src={index === chooseScene ? "/assets/images/home-active-icon.png" : "/assets/images/home-unactive-icon.png"} className='active-icon' alt="" />
                    </div>
                ))}
            </div>
            <div className='part-2-title2'>
                {i18n.t(`home_${sceneName}_title`)}
            </div>
            <div className='part-2-des'>
                {i18n.t(`home_${sceneName}_des`)}
            </div>
            <ul className="carousel__list">
                {items.map((item, index) => (
                    <li
                        key={item.id}
                        className={`carousel__item ${item.pos === 0 ? 'carousel__item_active' : ''}`}
                        data-pos={item.pos}
                        data-id={item.id}
                        onClick={() => handleClick(item.pos)}
                        id={"pos" + item.pos}
                    >
                        {/*   onMouseEnter={() => hanleMove(item.pos, 'moveIn')}
                        onMouseLeave={() => hanleMove(item.pos, "moveOut")} */}
                        <img src={item.src} />
                    </li>
                ))}
                <img src="/assets/images/home-enter-icon.png" onClick={goAnyWhere} alt="" className={`enter-icon ${isMoving ? 'is-moving' : ''}`} />

            </ul>
            <div className='visit-btn' onClick={goAnyWhere}>
                {i18n.t('home_visit_metaverse')}
            </div>
        </div>
    );
};

export default Carousel;
