import React, { useEffect, useState, useRef } from "react";
import SpeechRecognitionComponent from "./SpeechRecognitionComponent";
import "./speakChat.scss";
import { Paper } from "@mui/material";
import { Drawer, Radio, Button, Switch, Slider } from "antd";
import type { RadioChangeEvent } from "antd";
import { useTranslation } from "react-i18next";
import { zipImages } from "../../untils/until";
import TypingMessage from "../../untils/TypingMessage";
import config from "../../config";
import AudioPlayer from "./AudioPlayer";
const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: NodeJS.Timeout | null = null;
  return function (...args: any[]) {
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
};

const Test: React.FC = () => {
  const { i18n, t } = useTranslation();

  const speechRef = useRef<any>();
  const [transcript, setTranscript] = useState("");
  const [isAboveThreshold, setIsAboveThreshold] = useState(false);
  const initPage = useRef(false);
  const hasAwake = useRef<boolean>(false);
  const awakeMode = useRef(0);
  const [waiting, setWaiting] = useState(false);
  const hasSend = useRef<boolean>(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [dynamicContent, setDynamicContent] = useState("");
  // const [language, setLanguage] = useState(localStorage.getItem("language") === "CN" ? "zh-CN" : "en-US");
  const language = useRef<string>(
    localStorage.getItem("language") === "EN" ? "en-GB" : "zh-CN"
  );
  const [listening, setListening] = useState(false);
  const [input, setInput] = useState("");
  const inputVal = useRef<string>(""); // 用户要发送的文字
  const [isTyping, setIsTyping] = useState(false);
  const hasDone = useRef(true);
  const isRecover = useRef(false); //是否正在回复
  const [messages, setMessages] = useState<any>([]); // 显示的message数组
  const messageArr = useRef<any>([]); // 保存的message数组
  const [isSpeaking, setIsSpeaking] = useState(false); // NPC是否正在说话
  const textArr = useRef<any>([]); // 存储所有文字消息
  const [debug, setDebug] = useState(false);

  const audioPlayer = new AudioPlayer(); // NPC回复流式播放器

  const [questionArr, setQuestionArr] = useState<any>([
    [
      "What is Qiang embroidery?",
      "The historical background of Qiang embroidery?",
      "The artistic characteristics of Qiang embroidery.",
      "The main techniques of Qiang embroidery.",
    ],
    [
      "The cultural value of Qiang embroidery.",
      "Common patterns and their meanings in Qiang embroidery.",
      "The current state and challenges of Qiang embroidery inheritance.",
      "How can we protect Qiang embroidery?",
    ],
    [
      "The significance of preserving Qiang embroidery.",
      "The representative inheritors of Qiang embroidery techniques",
      "Where is the Qiang ethnic group located?",
      "The customs and culture of the Qiang people.",
    ],
  ]); //FAQ问题数组 英文
  const [questionArrCN, setQuestionArrCN] = useState([
    [
      "什么是羌绣？",
      "羌绣的历史背景。",
      "羌绣的艺术特色。",
      "羌绣的主要技法。",
    ],
    [
      "羌绣的文化价值。",
      "羌绣常见的图案及寓意。",
      "羌绣传承现状和问题。",
      "我们如何保护羌绣？",
    ],
    [
      "传承羌绣的意义。",
      "羌绣技艺代表性传承人。",
      "羌族在哪里？",
      "羌族在哪里？",
    ],
  ]);
  const [questionIndex, setQuestionIndex] = useState(0); //问题轮换索引
  const dbName = "qiangqiang";
  const storeName = "chatMessages";
  // const listening = useRef<boolean>(false);

  const base64ToArrayBuffer = (base64: string): ArrayBuffer => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  const handleTranscriptChange = (newTranscript: string) => {
    setTranscript(newTranscript);
    // console.log("????", hasAwake)
    if (hasAwake.current) {
      setInput(newTranscript);
      inputVal.current = newTranscript;
      console.log("handleTranscriptFinal", newTranscript);
    }
  };

  const handleTranscriptFinal = (newTranscript: string) => {
    setTranscript(newTranscript);
    // console.log("!!!!", hasAwake)
    if (hasAwake.current) {
      setInput(newTranscript);
      inputVal.current = newTranscript;
      console.log("handleTranscriptFinal", newTranscript);
    }
  };

  const handleVolumeChange = (aboveThreshold: boolean) => {
    setIsAboveThreshold(aboveThreshold);
    if (aboveThreshold) {
      // console.log("正在录音中");
    } else {
      // hasAwake.current = false;
      // console.log("???", input.trim())
      if (inputVal.current.trim().length > 1 && !hasSend.current) {
        hasAwake.current = false;
        handleSend();
      }
    }
  };

  const handleCommandRecognized = (command: string) => {
    if (!hasAwake.current) {
      console.log("被唤醒了");
      window.eventBus.emit('awake-audio');
      hasAwake.current = true;
      setTimeout(() => {
        // 清空输入内容
        setInput("");
        inputVal.current = "";
        console.log("清理输入内容");
        // 先暂停识别，避免提示语音干扰
        speechRef.current.pauseRecognition();
        setWaiting(true);

        // 播放语音提示
        const audioElement: any = document.getElementById("wait");
        audioElement.play();

        setTimeout(() => {
          // 恢复识别
          hasSend.current = false;
          speechRef.current.resumeRecognition();
        }, 2000);
      }, 200);
    }
  };

  const autoAwake = () => {
    if (awakeMode.current === 1) {
      hasAwake.current = true;
      setTimeout(() => {
        setWaiting(true);
        // 清空输入内容
        setInput("");
        inputVal.current = "";
        console.log("清理输入内容");
        hasSend.current = false;
        speechRef.current.resumeRecognition();
        console.log(hasAwake);
      }, 500);
    }
  };

  const startChineseRecognition = () => {
    if (speechRef.current) {
      speechRef.current.startChineseRecognition();
    }
  };

  const startEnglishRecognition = () => {
    if (speechRef.current) {
      speechRef.current.startEnglishRecognition();
    }
  };

  const resetRecognition = () => {
    if (speechRef.current) {
      speechRef.current.reset();
    }
  };

  const onAwakeChange = (e: RadioChangeEvent) => {
    console.log(`radio checked:${e.target.value}`);
    awakeMode.current = e.target.value;
  };

  const clearCache = () => {
    let request = indexedDB.deleteDatabase("qiangqiang");

    request.onsuccess = function (event) {
      console.log("数据库 qiangqiang 已成功删除");
      sessionStorage.removeItem("has-open-chat");
      window.location.reload();
    };

    request.onerror = function (event: any) {
      console.error("删除数据库出错:", event.target.error);
    };

    request.onblocked = function (event) {
      console.warn("删除数据库被阻止，请关闭所有其他标签页或窗口后再试。");
    };
  };

  const onDebugChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
    setDebug(checked);
  };

  // 切换语言
  const toggleLanguage = async () => {
    // resetartSpeech(false);
    // SpeechRecognition.stopListening();
    // console.log(language);
    let currentLanguage = language.current;
    if (currentLanguage === "zh-CN") {
      // to english
      language.current = "us-GB";
      i18n.changeLanguage("en");
    } else {
      language.current = "zh-CN";
      i18n.changeLanguage("cn");
    }
  };

  const toggleListening = async () => {
    if (listening) {
      // close
      speechRef.current.stopRecognition();
      setListening(false);
      hasAwake.current = false;
      setWaiting(false);
    } else {
      language.current === "zh-CN"
        ? startChineseRecognition()
        : startEnglishRecognition();
      setListening(true);
    }
  };

  const handleTypingComplete = (index: any) => {
    if (!hasDone.current) return;

    setIsTyping(false);
    // console.log("??????abcabcabc4");
    setMessages((prevMessages: any) => {
      const updatedMessages = [...prevMessages];
      updatedMessages[index].typing = 1;
      messageArr.current = updatedMessages;
      return updatedMessages;
    });
  };

  const scrollToBottom = () => {
    var bottomElement: any = document.getElementById("chat-box");
    bottomElement.scrollTop = bottomElement.scrollHeight;
  };

  // FAQ问题点击切换
  const nextQuestion = () => {
    setQuestionIndex((prevIndex) => (prevIndex + 1) % questionArr.length);
  };

  // 清空并添加数据
  const clearAndAddDataToIndexedDB = (db: IDBDatabase, dataArray: any[]) => {
    const transaction = db.transaction(storeName, "readwrite");
    const objectStore = transaction.objectStore(storeName);

    // 清空存储对象
    const clearRequest = objectStore.clear();

    clearRequest.onsuccess = () => {
      // 添加新数据
      dataArray.forEach((data) => {
        const addRequest = objectStore.add(data);
        addRequest.onerror = (event) => {
          console.error(
            "Error adding data:",
            (event.target as IDBRequest).error
          );
        };
      });
    };

    clearRequest.onerror = (event) => {
      console.error(
        "Error clearing object store:",
        (event.target as IDBRequest).error
      );
    };
  };

  // 存档聊天
  const saveMessage = () => {
    const request = indexedDB.open(dbName, 3);
    request.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest)?.result;
      const temp1 = messageArr.current.map((item: any, index: any) => {
        return {
          id: index,
          ...item,
          typing: 1,
        };
      });
      clearAndAddDataToIndexedDB(db, temp1);
    };
  };


  // 读取数据
  const readDataFromIndexedDB = (db: IDBDatabase) => {
    const transaction = db.transaction([storeName], 'readonly');
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.getAll();
    console.log('??????abcabcabcabcabcabcabc')
    request.onsuccess = (event) => {
      if ((event.target as IDBRequest).result?.length) {
        setMessages((event.target as IDBRequest).result);
        messageArr.current = (event.target as IDBRequest).result;
      }
    };

    request.onerror = (event) => {
      console.error('Error reading data:', (event.target as IDBRequest).error);
    };
  };

  //发送
  const handleSend = async (question?: any) => {
    hasSend.current = true;
    console.log(isTyping,isRecover.current,question,'??????ss')
    // 确保NPC正在回复的时候 无法重复提问
    if ((isRecover.current && question)) return;
    setWaiting(false);
    // const val = question || replaceWords(inputVal.current);
    const val = question || inputVal.current;
    console.log("发送消息", val);
    // resetartSpeech(false);
    // SpeechRecognition.stopListening();
    speechRef.current.pauseRecognition();
    if (val.trim()) {
      messageArr.current = [
        ...messageArr.current,
        { text: val, sender: "user", typing: 1 },
        { typing: 3, text: 1, sender: "bot" },
      ];
      setMessages((prevMessages: any) => {
        return [
          ...prevMessages,
          { text: val, sender: "user", typing: 1 },
          { typing: 3, text: 1, sender: "bot" },
        ];
      });
      setTimeout(() => {
        scrollToBottom();
      }, 100);

      setIsTyping(true);

      const lan = language.current === "zh-CN" ? "cn" : "en";
      const host = config.host;

      let eventSource: any = new EventSource(
        `${host}qwenStreamVoice?userMessage=` + val + "&lan=" + lan+"&source=XIU"
      );
      setIsSpeaking(true);
      isRecover.current = true;
      //   resetTranscript();
      setInput("");
      inputVal.current = "";
      let messageStr = "";
      const length = messageArr.current.length - 1;
      eventSource.onmessage = function (event: any) {
        if (event.data === "emitter end") {
          hasDone.current = true;
          eventSource.close();
          return;
        }

        const data = JSON.parse(event.data);

        if (data?.voiceMessage) {
          const base64AudioData = data?.voiceMessage;
          const arrayBuffer = base64ToArrayBuffer(base64AudioData);
          audioPlayer.receiveAudioData(arrayBuffer);
          setTimeout(() => {
            messageArr.current = textArr.current;
            setMessages(textArr.current);
            // 存档
            // saveMessage();
          }, 500);
        } else {
          const text = data.output.choices[0].message.content;
          messageStr += text;
          const temp = JSON.parse(JSON.stringify([...messageArr.current]));
          temp[length] = { text: messageStr, sender: "bot", typing: 2 };
          textArr.current = JSON.parse(JSON.stringify(temp));
        }
      };
    }
  };

  useEffect(() => {
    // NPC的语音回复停止
    window.eventBus.on("stop-play", () => {
      isRecover.current = false;
      autoAwake();
    });

    // 默认显示欢迎语 判断是不是第一次打开
    const str = t("scene_1_npc_desc").split("\n")[0];
    const str1 = t("scene_1_npc_desc").split("\n")[1];
    const str2 = t("scene_1_npc_desc").split("\n")[2];
    if (sessionStorage.getItem("has-open-chat")) {
      let temp: any = [
        {
          text: str,
          sender: "bot",
          typing: 1,
        },
        {
          text: str1,
          sender: "bot",
          typing: 1,
        },
        {
          text: str2,
          sender: "bot",
          typing: 1,
        },
      ];
      setMessages(temp);
      messageArr.current = temp;
      // const request = indexedDB.open(dbName, 3);
      // request.onsuccess = (event) => {
      //   const db = (event.target as IDBOpenDBRequest).result;
      //   // 读取数据
      //   readDataFromIndexedDB(db)
      // };
      // request.onerror = (event) => {
      //   console.error(
      //     "Error clearing object store:",
      //     (event.target as IDBRequest).error
      //   );
      // };
    } else {
      let obj = {};
      obj = {
        text: str,
        sender: "bot",
        typing: 2,
      };
      let temp: any = [];
      // temp[0] = obj;
      temp.push(obj);
      setMessages(temp);
      messageArr.current = temp;
      setTimeout(() => {
        obj = {
          text: str1,
          sender: "bot",
          typing: 2,
        };
        temp.push(obj);
        setMessages(temp);
        messageArr.current = temp;

        setTimeout(() => {
          obj = {
            text: str2,
            sender: "bot",
            typing: 2,
          };
          temp.push(obj);
          setMessages(temp);
          messageArr.current = temp;
          // const request = indexedDB.open(dbName, 3);
          // request.onupgradeneeded = (event) => {
          //   const db = (event.target as IDBOpenDBRequest).result;
          //   if (!db.objectStoreNames.contains(storeName)) {
          //     db.createObjectStore(storeName, {
          //       keyPath: "id",
          //       autoIncrement: true,
          //     });
          //     console.log(`Object store "${storeName}" created.`);
          //   }
          // };
          sessionStorage.setItem("has-open-chat", "true");
        }, 2100);
      }, 3200);
    }
    return () => {};
  }, []);

  return (
    // <div style={{ width: "100vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start"}}>
    //   <h1 style={{ color: "#fff" }}>Speech Recognition</h1>
    //   <SpeechRecognitionComponent
    //     ref={speechRef}
    //     onAwakeCommandRecognized={handleCommandRecognized}
    //     onTranscriptChange={handleTranscriptChange}
    //     onVolumeChange={handleVolumeChange}
    //   />
    //   <div>
    //     <p>Transcript: {transcript}</p>
    //     <p>Volume above threshold: {isAboveThreshold ? "Yes" : "No"}</p>
    //   </div>

    //   <button onClick={startChineseRecognition}>
    //     Start Chinese Recognition
    //   </button>
    //   <button onClick={startEnglishRecognition}>
    //     Start English Recognition
    //   </button>
    //   <button onClick={resetRecognition}>Reset</button>
    // </div>

    <div className="chat-dialog1">
      <video autoPlay={true} muted loop id="myVideo">
        <source
          src="https://oss.tnworld.cn/kunlun/assets/qiang-audio.mp4"
          type="video/mp4"
        />
      </video>
      <div className="bg-cover"></div>
      <div className="header">
        <Drawer
          title="设置"
          placement="top"
          width={500}
          onClose={() => setOpenSetting(false)}
          open={openSetting}
        >
          <div style={{ marginBottom: "20px" }}>唤醒模式</div>
          <Radio.Group onChange={onAwakeChange} defaultValue={0} block>
            <Radio.Button value={0}>你好羌羌</Radio.Button>
            <Radio.Button value={1}>自动</Radio.Button>
            <Radio.Button value={2} disabled>
              手动
            </Radio.Button>
          </Radio.Group>

          <div style={{ margin: "20px 0" }}>清空缓存</div>
          <Button type="primary" onClick={clearCache}>
            清空缓存并刷新
          </Button>

          <div style={{ margin: "20px 0" }}>开启识别工具调试</div>
          <Switch
            checkedChildren="开启"
            unCheckedChildren="关闭"
            onChange={onDebugChange}
          />
        </Drawer>

        <div className="close-box" onClick={() => setOpenSetting(true)}>
          <img src="/assets/images/menu-line.png" alt="" />
        </div>
        <div
          className="close-box"
          style={{ right: "60px" }}
          onClick={toggleLanguage}
        >
          {language.current === "zh-CN" ? "中" : "EN"}
        </div>

        <div
          className="close-box"
          style={{ right: "100px", width: "60px" }}
          onClick={toggleListening}
        >
          {listening ? "ON" : "OFF"}
        </div>

        <div className="dynamic-island">
          {transcript}
          {dynamicContent}
        </div>

        <audio
          id="wait"
          src={
            language.current === "zh-CN"
              ? "/assets/loongstella_cn.mp3"
              : "/assets/loongstella_en.mp3"
          }
        ></audio>
      </div>

      {waiting && (
        <div className="wait">
          <div className="boxContainer">
            <div className="box box1"></div>
            <div className="box box2"></div>
            <div className="box box3"></div>
            <div className="box box4"></div>
            <div className="box box5"></div>
          </div>

          <div
            className="send-btn"
            onClick={() => {
              handleSend();
            }}
          >
            SEND
          </div>
        </div>
      )}

      <div className="chat-container1" onClick={(e) => e.stopPropagation()}>
        <div className="chat-content1"></div>
        <div className="virtual">
          <img
            src={zipImages(
              "https://oss.tnworld.cn/kunlun/assets/xiu/xiuxiu.png"
            )}
            alt=""
          />
        </div>
        <div className="messages" id="chat-box">
          {messages.map((message: any, index: any) => (
            <div key={index} className={`message ${message.sender}`}>
              <Paper className="message-text">
                {message.typing == 2 ? (
                  <TypingMessage
                    message={message.text}
                    answering={() => setIsTyping(true)}
                    onComplete={() => handleTypingComplete(index)}
                    isTyping={isTyping}
                  />
                ) : message.typing == 1 ? (
                  message.text
                ) : (
                  <>
                    <span className="dot">.</span>
                    <span className="dot">.</span>
                    <span className="dot">.</span>
                  </>
                )}
              </Paper>
              {/* {(index == messages.length - 1 && isSpeaking) && <div className="stop-recover-box" onClick={()=>stopRecover(index)}>
                      <img src="/assets/images/stop-circle.png" alt="" />
                      <p>Stop Generating</p>
              </div>} */}
            </div>
          ))}
        </div>
        <div className="audio-chat-bottom-box">
          <div className="q-and-a-box">
            <div className="q-a-title">
              <p>FAQ</p>
              <img
                src="/assets/images/shuffle-line.png"
                alt=""
                onClick={nextQuestion}
              />
            </div>
            {language.current !== "us-GB" &&
              questionArrCN[questionIndex].map(
                (element: string, index: number) => (
                  <div
                    className="q-a-text"
                    key={index}
                    onClick={() => handleSend(element)}
                  >
                    {element}
                  </div>
                )
              )}
            {language.current === "us-GB" &&
              questionArr[questionIndex].map(
                (element: string, index: number) => (
                  <div
                    className="q-a-text"
                    key={index}
                    onClick={() => handleSend(element)}
                  >
                    {element}
                  </div>
                )
              )}
            <div className="parting-line"></div>
            <div className="input-container">
              <textarea
                // ref={inputRef}
                autoFocus={false}
                value={input}
                // onFocus={scrollToBottom}
                // onInput={(e: any) => {
                //   setInput(e.target.value);
                //   inputVal.current = e.target.value;
                // }}
                onKeyDown={(e) => {
                  e.key === "Enter" && handleSend();
                }}
                placeholder={
                  waiting ? i18n.t("chat_listening") : i18n.t("chat_start_tip")
                }
                disabled
                autoComplete="off"
              />
              {/* {!isListening && <AudioOutlined />}
              {isListening && <PauseOutlined onClick={endListing} />} */}
              <img
                src="/assets/images/arrow-back.png"
                onClick={handleSend}
                className="arrow-back1"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <SpeechRecognitionComponent
        ref={speechRef}
        onAwakeCommandRecognized={handleCommandRecognized}
        onTranscriptFinal={handleTranscriptFinal}
        onTranscriptChange={handleTranscriptChange}
        onVolumeChange={handleVolumeChange}
        debug={debug}
      />
    </div>
  );
};

export default Test;
