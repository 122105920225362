import React, { createContext, useContext, useEffect, useRef, useState, ReactNode } from 'react';

interface ScrollContextType {
  registerScrollHandler: (handler: (event: Event) => void) => void;
  unregisterScrollHandler: (handler: (event: Event) => void) => void;
}

const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

export const ScrollProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const handlersRef = useRef<Set<(event: Event) => void>>(new Set());
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = (event: WheelEvent) => {
      const container = scrollContainerRef.current;
      if (container) {

        // 手动创建并触发一个新的滚动事件
        const scrollEvent = new Event('scroll', { bubbles: true, cancelable: true });
        container.dispatchEvent(scrollEvent);
      }
      handlersRef.current.forEach(handler => handler(event));
    };

    window?.addEventListener('wheel', handleScroll, { passive: false });

    return () => {
      window?.removeEventListener('wheel', handleScroll);
    };
  }, []);

  const registerScrollHandler = (handler: (event: Event) => void) => {
    handlersRef.current.add(handler);
  };

  const unregisterScrollHandler = (handler: (event: Event) => void) => {
    handlersRef.current.delete(handler);
  };

  return (
    <ScrollContext.Provider value={{ registerScrollHandler, unregisterScrollHandler }}>
      <div ref={scrollContainerRef} style={{ height: '100vh', overflow: 'auto' }}>
        {children}
      </div>
    </ScrollContext.Provider>
  );
};

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  return context;
};
