import React, { useEffect, useState } from 'react';
import './modal.scss';
import { useTranslation } from 'react-i18next';

const Moadl = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFadingOut, setIsFadingOut] = useState(false);
    const { i18n } = useTranslation();

    useEffect(() => {
        window.eventBus.on(`openModal`, () => {
            setIsModalOpen(true);
            setIsFadingOut(false);
        })
    }, []);
    // 关闭弹窗 为了显示动画效果 做延时关闭
    const closeModal = () => {
        setIsFadingOut(true);
        setIsModalOpen(false);
        setTimeout(() => {
            window.eventBus.emit('closeDialog');
        }, 200)
    };

    return (
        <>
            {isModalOpen && <div className="message-dialog1" onClick={closeModal}>
                {isModalOpen && <div className="close-box">
                    <img src="/assets/images/close-icon.png" alt="" />
                </div>}
                <div className={`message-dialog-center ${isFadingOut ? 'fade-out' : ''}`} onClick={(e) => e.stopPropagation()}>
                    <img src={`/assets/images/rectangle-dark.png`} className="left-top-border" alt="" />
                    <img src={`/assets/images/rectangle-dark.png`} className="right-top-border" alt="" />
                    <img src={`/assets/images/rectangle-dark.png`} className="left-bottom-border" alt="" />
                    <img src={`/assets/images/rectangle-dark.png`} className="right-bottom-border" alt="" />
                    <div className='message-dialog-box-dark'>
                        <div className="message-text">
                            敬请期待...
                        </div>
                    </div>
                </div>
            </div>}
        </>

    );
};

export default Moadl;
