import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import MessageDialog from '../../components/dialog/MessageDialog';
import { useTranslation } from 'react-i18next';
import Header from '../../components/header/Header';
import PartOne from './partOne/PartOne';
import PartTwo from './partTwo/PartTwo';
import PartThree from './partThree/PartThree';
import PartFour from './partFour/PartFour';
import ScreenTip from "../../components/screen-tip/ScreenTip";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [offset, setOffset] = useState(100);
  const [showDialog, setShowDialog] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeTab, setActiveTab] = useState('part-1');
  const imgArr = [{
    src: '/assets/images/p1-dark-corner.png',
  }];
  const [mobile, setMobile] = useState(false);

  const partOneRef = useRef<HTMLDivElement>(null);
  const partTwoRef = useRef<HTMLDivElement>(null);
  const partThreeRef = useRef<HTMLDivElement>(null);
  const partFourRef = useRef<HTMLDivElement>(null);
  document.title = 'Kunlun Legends of the east';
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  const refs: any = {
    'part-1': partOneRef,
    'part-2': partTwoRef,
    'part-3': partThreeRef,
    'part-4': partFourRef
  };

  const isMobile = () => {
    setMobile(typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1 ||
      navigator.userAgent.indexOf("iPhone") !== -1 ||
      (navigator.userAgent.indexOf("Android") !== -1 &&
        navigator.userAgent.indexOf("Mobile") !== -1) ||
      navigator.userAgent.indexOf("BlackBerry") !== -1 ||
      navigator.userAgent.indexOf("Opera Mini") !== -1)
  };

  const targetScrollTop = useRef(0);
  const currentScrollTop = useRef(0);
  const isScrolling = useRef(false);

  const scrollSpeedFactor = 0.7;

  const smoothScroll = () => {
    const appElement: any = document.getElementById('app');

    const scrollStep = () => {
      const distance = targetScrollTop.current - currentScrollTop.current;
      if (Math.abs(distance) < 1) {
        currentScrollTop.current = targetScrollTop.current;
        isScrolling.current = false;
      } else {
        currentScrollTop.current += distance * 0.1;
        isScrolling.current = true;
      }
      appElement.scrollTop = currentScrollTop.current;
      setScrollPosition(appElement.scrollTop);
      updateActiveTab(appElement.scrollTop);

      if (isScrolling.current) {
        requestAnimationFrame(scrollStep);
      }
    };
    requestAnimationFrame(scrollStep);
  };

  const slowScroll = (event: any) => {
    event.preventDefault();

    const delta = event.deltaY * scrollSpeedFactor;
    targetScrollTop.current += delta;
    targetScrollTop.current = Math.max(0, Math.min(targetScrollTop.current, document.getElementById('app')!.scrollHeight - window.innerHeight));
    if (!isScrolling.current) {
      isScrolling.current = true;
      smoothScroll();
    }
  };

  const scrollToPosition = (position: number) => {
    targetScrollTop.current = Math.max(0, Math.min(position, document.getElementById('app')!.scrollHeight - window.innerHeight));
    if (!isScrolling.current) {
      isScrolling.current = true;
      smoothScroll();
    }
  };

  const handleNavClick = (id: string) => {
    const ref = refs[id];
    if (ref && ref.current) {
      const position = ref.current.offsetTop;
      scrollToPosition(position);
      setActiveTab(id);
    }
  };

  const updateActiveTab = (scrollTop: number) => {
    const threshold = 100;

    const partOneTop = partOneRef.current?.offsetTop || 0;
    const partTwoTop = partTwoRef.current?.offsetTop || 0;
    const partThreeTop = partThreeRef.current?.offsetTop || 0;

    if (scrollTop >= partThreeTop - threshold) {
      setActiveTab('part-3');
    } else if (scrollTop >= partTwoTop - threshold) {
      setActiveTab('part-2');
    } else {
      setActiveTab('part-1');
    }
  };

  useEffect(() => {
    isMobile();
    const appElement: any = document.getElementById('app');
    appElement?.addEventListener('wheel', slowScroll, { passive: false });

    let touchStartY = 0;

    const handleTouchStart = (event: TouchEvent) => {
      touchStartY = event.touches[0].clientY;
    };

    const handleTouchMove = (event: TouchEvent) => {
      event.preventDefault();  // Prevent default to avoid triggering pull-to-refresh
      const touchCurrentY = event.touches[0].clientY;
      const delta = (touchStartY - touchCurrentY) * 0.8;
      targetScrollTop.current += delta;
      targetScrollTop.current = Math.max(0, Math.min(targetScrollTop.current, document.getElementById('app')!.scrollHeight - window.innerHeight));
      if (!isScrolling.current) {
        isScrolling.current = true;
        smoothScroll();
      }
      touchStartY = touchCurrentY;
    };

    appElement?.addEventListener('touchstart', handleTouchStart, { passive: true });
    appElement?.addEventListener('touchmove', handleTouchMove, { passive: false });

    // 禁用下拉刷新
    document?.addEventListener('touchmove', (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    }, { passive: false });

    return () => {
      appElement.removeEventListener('wheel', slowScroll);
      appElement.removeEventListener('touchstart', handleTouchStart);
      appElement.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchmove', (event) => {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      });
    };
  }, []);

  const handleOrientationChange = () => {
    setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
  };

  // 监听屏幕旋转
  window?.addEventListener("resize", handleOrientationChange);
  window?.addEventListener("orientationchange", handleOrientationChange);

  const goAnyWhere = (url: string) => {
    navigate(url, { state: { id: 123, num: 321 } });
  };

  return (
    <>
      <Header onNavClick={handleNavClick} activeTab={activeTab} />
      {(!isPortrait && mobile) && <ScreenTip />}
      <div id="app" style={{ width: '100vw', height: '100vh', overflow: 'hidden', position: 'relative' }}>
        <div id="part-1" ref={partOneRef} style={{ width: '100vw', position: 'relative' }}>
          <PartOne scrollPosition={scrollPosition} mobile={mobile} />
        </div>
        <div id="part-2" ref={partTwoRef} style={{ width: '100vw', position: 'relative' }}>
          <PartTwo scrollPosition={scrollPosition} mobile={mobile}/>
        </div>
        <div id="part-3" ref={partThreeRef} style={{ width: '100vw', position: 'relative' }}>
          <PartThree scrollPosition={scrollPosition} mobile={mobile}/>
        </div>
        <div id="part-4" ref={partFourRef} style={{ width: '100vw', position: 'relative' }}>
          <PartFour scrollPosition={scrollPosition} mobile={mobile}/>
        </div>
      </div>
    </>
  );
};

export default Home;
