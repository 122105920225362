declare let window: any;
declare let Math: any;
declare let document: any;
declare let navigator: any;
declare let Promise: any;

// 构造函数参数格式
interface recorderConfig {
    sampleBits?: number,        // 采样位数
    sampleRate?: number,        // 采样率
    numChannels?: number,       // 声道数
    compiling?: boolean,        // 是否边录边播
}

interface dataview {
    byteLength: number,
    buffer: {
        byteLength: number,
    },
    getUint8: any,
}

interface DataViewExtended  {
    getUint8(offset: number): number;
    byteLength: number;
    buffer: {
        byteLength: number;
    }
}

class Recorder {
    private isRecording: boolean = false;               // 是否正在录音
    private isPlaying: boolean = false;                 // 是否正在播放
    private isPause: boolean = false;                   // 是否是暂停
    private context: AudioContext | null = null;
    private config: any;                     // 配置
    private size: number = 0;                           // 录音文件总长度
    private lBuffer: Array<Float32Array> = [];          // pcm音频数据搜集器(左声道)
    private rBuffer: Array<Float32Array> = [];          // pcm音频数据搜集器(右声道)
    private PCM: DataView | null = null;                // 最终的PCM数据缓存，避免多次encode
    private tempPCM: Array<DataView> = [];              // 边录边转时临时存放pcm的
    private audioInput: MediaStreamAudioSourceNode | null = null;
    private inputSampleRate: number = 0;                // 输入采样率
    private source: AudioBufferSourceNode | null = null;  // 音频输入
    private recorder: any | null = null;
    private inputSampleBits: number = 16;              // 输入采样位数
    private outputSampleRate: number;                  // 输出采样率
    private outputSampleBits: number;                  // 输出采样位数
    private analyser: any | null = null;
    private littleEndian: boolean;                     // 是否是小端字节序
    private prevDomainData: Uint8Array | null = null;  // 存放前一次图形化的数据
    private playStamp: number = 0;                     // 播放录音时 AudioContext 记录的时间戳
    private playTime: number = 0;                      // 记录录音播放时长
    private totalPlayTime: number = 0;                 // 音频播放总长度
    private offset: number = 0;                        // 边录边转，记录外部的获取偏移位置
    private stream: MediaStream | null = null;         // 流

    public fileSize: number = 0;                       // 录音大小，byte为单位
    public duration: number = 0;                       // 录音时长
    // 正在录音时间，参数是已经录了多少时间了
    public onprocess?: (duration: number) => void;
    // onprocess 替代函数，保持原来的 onprocess 向下兼容
    public onprogress?: (payload: {
        duration: number,
        fileSize: number,
        vol: number,
        data: Array<DataView>,      // 当前存储的所有录音数据
    }) => void;
    
    constructor(options: any = {}) {
        const context = new (window.AudioContext || window.webkitAudioContext)();
        this.inputSampleRate = context.sampleRate;     // 获取当前输入的采样率
        this.config = {
            sampleBits: [8, 16].includes(options.sampleBits || 16) ? options.sampleBits! : 16,
            sampleRate: [8000, 11025, 16000, 22050, 24000, 44100, 48000].includes(options.sampleRate || context.sampleRate) ? options.sampleRate! : context.sampleRate,
            numChannels: [1, 2].includes(options.numChannels || 1) ? options.numChannels! : 1,
            compiling: !!options.compiling || false,
        };
        this.outputSampleRate = this.config.sampleRate;
        this.outputSampleBits = this.config.sampleBits;
        this.littleEndian = (function() {
            const buffer = new ArrayBuffer(2);
            new DataView(buffer).setInt16(0, 256, true);
            return new Int16Array(buffer)[0] === 256;
        })();
        Recorder.initUserMedia();
    }

    initRecorder(): void {
        if (this.context) {
            this.destroy();
        }
        this.context = new (window.AudioContext || window.webkitAudioContext)();
        this.analyser = (this.context as any).createAnalyser();
        this.analyser.fftSize = 2048;
        
        const createScript = this.context?.createScriptProcessor || (this.context as any)?.createJavaScriptNode;
        this.recorder = createScript.apply(this.context, [4096, this.config.numChannels, this.config.numChannels]);

        this.recorder.onaudioprocess = (e: AudioProcessingEvent) => {
            if (!this.isRecording || this.isPause) {
                return;
            }
            const lData = e.inputBuffer.getChannelData(0);
            this.lBuffer.push(new Float32Array(lData));
            this.size += lData.length;

            let rData: Float32Array | null = null;
            if (this.config.numChannels === 2) {
                rData = e.inputBuffer.getChannelData(1);
                this.rBuffer.push(new Float32Array(rData));
                this.size += rData.length;
            }

            if (this.config.compiling) {
                const pcm = this.transformIntoPCM(lData, rData);
                this.tempPCM.push(pcm);
                this.fileSize = pcm.byteLength * this.tempPCM.length;
            } else {
                this.fileSize = Math.floor(this.size / Math.max(this.inputSampleRate / this.outputSampleRate, 1))
                    * (this.outputSampleBits / 8);
            }
            const vol:number = Math.max(...Array.from(lData as Float32Array)) * 100;
            this.duration += 4096 / this.inputSampleRate;
            this.onprocess && this.onprocess(this.duration);
            this.onprogress && this.onprogress({
                duration: this.duration,
                fileSize: this.fileSize,
                vol,
                data: this.tempPCM,
            });
        }
    }

    start(): Promise<void> {
        if (this.isRecording) {
            return Promise.resolve();
        }
        this.clear();
        this.initRecorder();
        this.isRecording = true;

        return navigator.mediaDevices.getUserMedia({ audio: true }).then((stream:any) => {
            this.audioInput = this.context!.createMediaStreamSource(stream);
            this.stream = stream;
            this.audioInput.connect(this.analyser!);
            this.analyser!.connect(this.recorder!);
            this.recorder!.connect(this.context!.destination);
        });
    }
    
    pause(): void {
        if (this.isRecording && !this.isPause) {
            this.isPause = true;
        }
    }

    resume(): void {
        if (this.isRecording && this.isPause) {
            this.isPause = false;
        }
    }

    stop(): void {
        this.isRecording = false;
        if (this.audioInput) this.audioInput.disconnect();
        if (this.recorder) this.recorder.disconnect();
    }

    play(): void {
        this.stop();
        if (this.source) this.source.stop();
        this.isPlaying = true;
        this.playTime = 0;
        this.playAudioData();
    }

    getPlayTime(): number {
        let now = 0;
        if (this.isPlaying) {
            now = this.context!.currentTime - this.playStamp + this.playTime;
        } else {
            now = this.playTime;
        }
        if (now >= this.totalPlayTime) {
            now = this.totalPlayTime;
        }
        return now;
    }

    pausePlay(): void {
        if (this.isRecording || !this.isPlaying) {
            return;
        }
        if (this.source) this.source.disconnect();
        this.playTime += this.context!.currentTime - this.playStamp;
        this.isPlaying = false;
    }

    resumePlay(): void {
        if (this.isRecording || this.isPlaying || this.playTime === 0) {
            return;
        }
        this.isPlaying = true;
        this.playAudioData();
    }

    stopPlay(): void {
        if (this.isRecording) {
            return;
        }
        this.playTime = 0;
        this.isPlaying = false;
        if (this.source) this.source.stop();
    }

    getWholeData(): Array<DataView> {
        return this.tempPCM;
    }

    getNextData(): any {
        const length = this.tempPCM.length;
        const data = this.tempPCM.slice(this.offset);
        this.offset = length;
        return data;
    }

    private playAudioData(): void {
        const buffer = this.getWAV().buffer;
        this.context!.decodeAudioData(buffer).then(decodedData => {
            this.source = this.context!.createBufferSource();
            this.source.buffer = decodedData;
            this.totalPlayTime = this.source.buffer!.duration;
            this.source.connect(this.analyser!);
            this.analyser!.connect(this.context!.destination);
            this.source.start(0, this.playTime);
            this.playStamp = this.context!.currentTime;
        }).catch(e => {
            Recorder.throwError(e);
        });
    }

    getRecordAnalyseData(): Uint8Array | null {
        if (this.isPause) {
            return this.prevDomainData;
        }
        const dataArray = new Uint8Array(this.analyser!.frequencyBinCount);
        this.analyser!.getByteTimeDomainData(dataArray);
        this.prevDomainData = dataArray;
        return dataArray;
    }

    getPlayAnalyseData(): Uint8Array | null {
        return this.getRecordAnalyseData();
    }

    private getPCM(): DataView {
        if (this.tempPCM.length) {
            const buffer = new ArrayBuffer(this.tempPCM.length * this.tempPCM[0].byteLength);
            const pcm = new DataView(buffer);
            let offset = 0;
            this.tempPCM.forEach((block) => {
                for (let i = 0, len = block.byteLength; i < len; ++i) {
                    pcm.setInt8(offset, block.getInt8(i));
                    offset++;
                }
            });
            this.PCM = pcm;
            this.tempPCM = [];
        }
        if (this.PCM) {
            return this.PCM;
        }
        const data = this.flat();
        const compressedData = Recorder.compress(data, this.inputSampleRate, this.outputSampleRate);
        return this.PCM = Recorder.encodePCM(compressedData, this.outputSampleBits, this.littleEndian);
    }

    getPCMBlob(): Blob {
        this.stop();
        return new Blob([this.getPCM()]);
    }

    downloadPCM(name: string = 'recorder'): void {
        const pcmBlob = this.getPCMBlob();
        this.download(pcmBlob, name, 'pcm');
    }

    private getWAV(): DataView {
        const pcmTemp = this.getPCM();
        const wavTemp = Recorder.encodeWAV(pcmTemp, this.inputSampleRate, 
            this.outputSampleRate, this.config.numChannels, this.outputSampleBits, this.littleEndian);
        return wavTemp;
    }

    getWAVBlob(): Blob {
        this.stop();
        return new Blob([this.getWAV()], { type: 'audio/wav' });
    }

    downloadWAV(name: string = 'recorder'): void {
        const wavBlob = this.getWAVBlob();
        this.download(wavBlob, name, 'wav');
    }

    private transformIntoPCM(lData: Float32Array, rData: Float32Array | null): DataView {
        const lBuffer = new Float32Array(lData);
        const rBuffer = rData ? new Float32Array(rData) : new Float32Array(0);
        const data = Recorder.compress({ left: lBuffer, right: rBuffer }, this.inputSampleRate, this.outputSampleRate);
        return Recorder.encodePCM(data, this.outputSampleBits, this.littleEndian);
    }

    destroy(): Promise<void> {
        this.stopStream();
        return this.closeAudioContext();
    }

    private stopStream(): void {
        if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
            this.stream = null;
        }
    }

    private closeAudioContext(): Promise<void> {
        if (this.context && this.context.close && this.context.state !== 'closed') {
            return this.context.close();
        } else {
            return Promise.resolve();
        }
    }

    private download(blob: Blob, name: string, type: string): void {
        try {
            const oA = document.createElement('a');
            oA.href = window.URL.createObjectURL(blob);
            oA.download = name + '.' + type;
            oA.click();
        } catch(e:any) {
            Recorder.throwError(e);
        }
    }

    private clear(): void {
        this.lBuffer.length = 0;
        this.rBuffer.length = 0;
        this.size = 0;
        this.fileSize = 0;
        this.PCM = null;
        this.audioInput = null;
        this.duration = 0;
        this.isPause = false;
        this.isPlaying = false;
        this.playTime = 0;
        this.totalPlayTime = 0;
        if (this.source) {
            this.source.stop();
            this.source = null;
        }
    }

    private flat(): { left: Float32Array, right: Float32Array } {
        const lData = new Float32Array(this.size / (this.config.numChannels || 1));
        const rData = this.config.numChannels === 2 ? new Float32Array(this.size / 2) : new Float32Array(0);
        let offset = 0;
        for (let i = 0; i < this.lBuffer.length; i++) {
            lData.set(this.lBuffer[i], offset);
            offset += this.lBuffer[i].length;
        }
        offset = 0;
        for (let i = 0; i < this.rBuffer.length; i++) {
            rData.set(this.rBuffer[i], offset);
            offset += this.rBuffer[i].length;
        }
        return { left: lData, right: rData };
    }

    static playAudio(blob: Blob): void {
        const oAudio = document.createElement('audio');
        oAudio.src = window.URL.createObjectURL(blob);
        oAudio.play();
    }

    static compress(data: { left: Float32Array, right: Float32Array | null }, inputSampleRate: number, outputSampleRate: number): Float32Array {
        const rate = inputSampleRate / outputSampleRate;
        const compression = Math.max(rate, 1);
        const lData = data.left;
        const rData = data.right || new Float32Array(0);
        const length = Math.floor((lData.length + rData.length) / rate);
        const result = new Float32Array(length);
        let index = 0;
        let j = 0;

        while (index < length) {
            const temp = Math.floor(j);
            result[index] = lData[temp];
            index++;
            if (rData.length) {
                result[index] = rData[temp];
                index++;
            }
            j += compression;
        }
        return result;
    }

    static encodePCM(bytes: Float32Array, sampleBits: number, littleEndian: boolean = true): DataView {
        const dataLength = bytes.length * (sampleBits / 8);
        const buffer = new ArrayBuffer(dataLength);
        const data = new DataView(buffer);
        let offset = 0;

        if (sampleBits === 8) {
            for (let i = 0; i < bytes.length; i++, offset++) {
                let s = Math.max(-1, Math.min(1, bytes[i]));
                let val = s < 0 ? s * 128 : s * 127;
                val = +val + 128;
                data.setInt8(offset, val);
            }
        } else {
            for (let i = 0; i < bytes.length; i++, offset += 2) {
                let s = Math.max(-1, Math.min(1, bytes[i]));
                data.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, littleEndian);
            }
        }
        return data;
    }

    static encodeWAV(bytes: DataViewExtended, inputSampleRate: number, outputSampleRate: number, numChannels: number, outputSampleBits: number, littleEndian: boolean = true): DataView {
        const sampleRate = outputSampleRate > inputSampleRate ? inputSampleRate : outputSampleRate;
        const buffer = new ArrayBuffer(44 + bytes.byteLength);
        const data = new DataView(buffer);
        const channelCount = numChannels;
        let offset = 0;

        writeString(data, offset, 'RIFF'); offset += 4;
        data.setUint32(offset, 36 + bytes.byteLength, littleEndian); offset += 4;
        writeString(data, offset, 'WAVE'); offset += 4;
        writeString(data, offset, 'fmt '); offset += 4;
        data.setUint32(offset, 16, littleEndian); offset += 4;
        data.setUint16(offset, 1, littleEndian); offset += 2;
        data.setUint16(offset, channelCount, littleEndian); offset += 2;
        data.setUint32(offset, sampleRate, littleEndian); offset += 4;
        data.setUint32(offset, channelCount * sampleRate * (outputSampleBits / 8), littleEndian); offset += 4;
        data.setUint16(offset, channelCount * (outputSampleBits / 8), littleEndian); offset += 2;
        data.setUint16(offset, outputSampleBits, littleEndian); offset += 2;
        writeString(data, offset, 'data'); offset += 4;
        data.setUint32(offset, bytes.byteLength, littleEndian); offset += 4;

        for (let i = 0; i < bytes.byteLength; i++) {
            data.setUint8(offset, bytes.getUint8(i));
            offset++;
        }
        return data;
    }









    /**
     * 异常处理
     * @static
     * @param {*} message   错误消息
     * @memberof Recorder
     */
    static throwError(message: string) {
        throw new Error (message);
    }

    // getUserMedia 版本兼容
    static initUserMedia() {
        if (navigator.mediaDevices === undefined) {
            navigator.mediaDevices = {};
        }
        
        if (navigator.mediaDevices.getUserMedia === undefined) {
            navigator.mediaDevices.getUserMedia = function(constraints:any) {
                let getUserMedia = navigator?.getUserMedia || navigator?.webkitGetUserMedia || navigator?.mozGetUserMedia;
                
                if (!getUserMedia) {
                    return Promise.reject(new Error('浏览器不支持 getUserMedia !'));
                }
                
                return new Promise(function(resolve:any, reject:any) {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
            }
        }
    }

    /**
     * 在没有权限的时候，让弹出获取麦克风弹窗
     *
     * @static
     * @returns {Promise<{}>}
     * @memberof Recorder
     */
    static getPermission(): Promise<{}> {
        this.initUserMedia();

        return navigator.mediaDevices.getUserMedia({audio: true}).then((stream:any) => {
            stream.getTracks().forEach((track:any) => track.stop());
        });
    }
}

/**
 * 在data中的offset位置开始写入str字符串
 * @param {TypedArrays} data    二进制数据
 * @param {Number}      offset  偏移量
 * @param {String}      str     字符串
 */
function writeString(data:any, offset:any, str:any): void {
    for (let i = 0; i < str.length; i++) {
        data.setUint8(offset + i, str.charCodeAt(i));
    }
}

export default Recorder;