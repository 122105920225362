import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import createCommands from "./SpeechCommand";

interface SpeechRecognitionProps {
  onTranscriptChange: (transcript: string) => void;
  onTranscriptFinal: (transcript: string) => void;
  onVolumeChange: (isAboveThreshold: boolean) => void;
  onAwakeCommandRecognized: (command: string) => void;
  debug: boolean
}

const SpeechRecognitionComponent = forwardRef<unknown, SpeechRecognitionProps>(
  ({ onTranscriptChange, onTranscriptFinal, onVolumeChange, onAwakeCommandRecognized, debug = false}, ref) => {
    const [transcribing, setTranscribing] = useState(true);
    const [clearTranscriptOnListen, setClearTranscriptOnListen] = useState(true);

    const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
    const [analyser, setAnalyser] = useState<AnalyserNode | null>(null);
    const [decibels, setDecibels] = useState<any>(0);
    const [threshold, setThreshold] = useState<any>(-25);
    const toggleTranscribing = () => setTranscribing(!transcribing);
    const toggleClearTranscriptOnListen = () => {
      setClearTranscriptOnListen(!clearTranscriptOnListen);
    };

    const needTofix = useRef<string>("")
    const fixKeyword = useRef<string>("")
    const currrentReplace = useRef<string>("")

    const onFixKeywords = (command: string, keyword: string) => {
      needTofix.current = command
      fixKeyword.current = keyword
    }


    // 唤醒命令，替换关键词命令
    const commands = createCommands(onAwakeCommandRecognized, onFixKeywords);

    const {
      transcript,
      interimTranscript,
      finalTranscript,
      resetTranscript,
      listening,
      browserSupportsSpeechRecognition,
      isMicrophoneAvailable,
    } = useSpeechRecognition({ transcribing, clearTranscriptOnListen, commands });

    const listenOnce = () => {
      SpeechRecognition.startListening({ continuous: false });
    };

    const listenContinuously = () => {
      SpeechRecognition.startListening({
        continuous: true,
        language: "en-GB",
      });
    };

    const listenContinuouslyInChinese = () => {
      SpeechRecognition.startListening({
        continuous: true,
        language: "zh-CN",
      });
    };

    const stopListening = () => {
      SpeechRecognition.stopListening()
    };

    const pauseListening = () => {
      setTranscribing(false)
      resetTranscript()
    };

    const resumeListening = () => {
      setTranscribing(true)
    }

    useEffect(() => {
      let result: any = ""
      if (interimTranscript !== "") {
        result = interimTranscript
        if(needTofix.current.trim() !== "") {
          const regex = new RegExp(needTofix.current, 'g');
          result = result.replace(regex, fixKeyword.current);
        }
        onTranscriptChange(result);
      }
      if (finalTranscript !== "") {
        result = finalTranscript
        if(needTofix.current.trim() !== "") {
          const regex = new RegExp(needTofix.current, 'g');
          result = result.replace(regex, fixKeyword.current);
        }
        onTranscriptFinal(result);
      }
    }, [interimTranscript, finalTranscript, onTranscriptChange]);

    useEffect(() => {
      const initAudio = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const context = new AudioContext();
        const analyserNode = context.createAnalyser();
        const source = context.createMediaStreamSource(stream);
        source.connect(analyserNode);
        setAudioContext(context);
        setAnalyser(analyserNode);
      };

      initAudio();

      return () => {
        SpeechRecognition.stopListening();
        if (audioContext) {
          audioContext.close();
        }
      };
    }, []);

    useEffect(() => {
      if (analyser) {
        const dataArray = new Uint8Array(analyser.fftSize);
        let lastAboveThreshold = Date.now();
        // const threshold = -25;
        const stopDuration = 3000; // 3 seconds

        const detectVolume = () => {
          analyser.getByteTimeDomainData(dataArray);
          let sum = 0;
          for (let i = 0; i < dataArray.length; i++) {
            const value = dataArray[i] / 128 - 1;
            sum += value * value;
          }
          const rms = Math.sqrt(sum / dataArray.length);
          const db = 20 * Math.log10(rms);
          const decibels = Math.max(db, -Infinity);

          setDecibels((decibels))
          

          if (decibels > threshold) {
            lastAboveThreshold = Date.now();
            if (!listening) {
              setTranscribing(true);
            }
            onVolumeChange(true);
          } else {
            if (Date.now() - lastAboveThreshold > stopDuration) {
              if (listening) {
                setTranscribing(false);
                resetTranscript();
              }
              onVolumeChange(false);
            }
          }
          setTimeout(detectVolume, 200);
        };

        detectVolume();
      }
    }, [analyser, listening, onVolumeChange]);

    useImperativeHandle(ref, () => ({
      startChineseRecognition: listenContinuouslyInChinese,
      startEnglishRecognition: listenContinuously,
      reset: resetTranscript,
      stopRecognition: stopListening,
      pauseRecognition: pauseListening,
      resumeRecognition: resumeListening,

    }));

    if (!browserSupportsSpeechRecognition) {
      return <span>Browser doesn't support speech recognition.</span>;
    }

    if (!isMicrophoneAvailable) {
      return <span>Please allow access to the microphone</span>;
    }

    return (
      <div style={{ width: "50vw", position: "fixed", "bottom": "50px", zIndex: "999", display: debug?"flex":"none", flexDirection: "column", background: "#fff", borderRadius: "20px", padding: "20px" }}>
        <h2>组件内部</h2>
        <span >
          listening: {listening ? "on" : "off"}
        </span>
        <span >
          transcribing: {transcribing ? "on" : "off"}
        </span>
        <span >
          clearTranscriptOnListen: {clearTranscriptOnListen ? "on" : "off"}
        </span>
        <span >
          {/* current decibels: {decibels} */}
        </span>
        <span>decibels threshold : <input type="number" value={threshold} onChange={(e) => setThreshold(Number(e.target.value))}/></span>

        <button onClick={listenOnce}>Listen once</button>
        <button onClick={listenContinuously}>
          Listen continuously（English）
        </button>
        <button onClick={listenContinuouslyInChinese}>
          Listen continuously（Chinese）
        </button>
        <button onClick={resetTranscript}>Reset</button>
        <button onClick={toggleTranscribing}>Toggle transcribing</button>
        <button onClick={toggleClearTranscriptOnListen}></button>
      </div>
    );
  }
);

export default SpeechRecognitionComponent;
