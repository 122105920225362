// const commands = [
//   {
//     command: 'I would like to order *',
//     callback: (food) => setMessage(`Your order is for: ${food}`),
//     matchInterim: true
//   },
//   {
//     command: 'The weather is :condition today',
//     callback: (condition) => setMessage(`Today, the weather is ${condition}`)
//   },
//   {
//     command: ['Hello', 'Hi'],
//     callback: ({ command }) => setMessage(`Hi there! You said: "${command}"`),
//     matchInterim: true
//   },
//   {
//     command: 'Beijing',
//     callback: (command, spokenPhrase, similarityRatio) => setMessage(`${command} and ${spokenPhrase} are ${similarityRatio * 100}% similar`),
//     // If the spokenPhrase is "Benji", the message would be "Beijing and Benji are 40% similar"
//     isFuzzyMatch: true,
//     fuzzyMatchingThreshold: 0.2
//   },
//   {
//     command: ['eat', 'sleep', 'leave'],
//     callback: (command) => setMessage(`Best matching command: ${command}`),
//     isFuzzyMatch: true,
//     fuzzyMatchingThreshold: 0.2,
//     bestMatchOnly: true
//   },
//   {
//     command: 'clear',
//     callback: ({ resetTranscript }) => resetTranscript(),
//     matchInterim: true
//   }
// ]

import { keyboard } from "@testing-library/user-event/dist/keyboard";

// SpeechCommand.ts


export default function createCommands(onCommandRecognized: (command: string) => void, onFixKeywords:(command: string, keyword: string) => void) {
  return [
    {
      command: ['你好羌羌', '嗨羌羌', "Hi羌羌", "Hello羌羌"],
      callback: (command: any) => {
        // console.log("???", command);
        onCommandRecognized(command); // 调用父组件的事件处理函数
      },
      matchInterim: true,
      isFuzzyMatch: true,
      fuzzyMatchingThreshold: 0.2,
    },
    {
      command: [
        "强强"
      ],
      callback: (command: any, transcript: any) => {
        // console.log("???", command);
        console.log("???", command, transcript)
        onFixKeywords(command, '羌羌')
      },
      matchInterim: true,
      isFuzzyMatch: true,
      fuzzyMatchingThreshold: 0.1,
    },
    {
      command: [
        "抢修", "强休", "枪袖", "墙秀", "强袖", "枪绣", "强修", "墙绣", 
        "枪秀", "强秀", "千秀", "强舆", "枪宿", "墙宿", "枪修", "强宿", 
        "枪绪", "强绪", "墙绪", "枪叙", "强叙", "墙叙", "枪须", "强须", 
        "墙须", "枪絮", "强絮", "墙絮", "枪续", "强续", "墙续", "将臭", 
        "湘绣"
      ],
      callback: (command: any, transcript: any) => {
        // console.log("???", command);
        console.log("???", command, transcript)
        onFixKeywords(command, '羌绣')
      },
      matchInterim: true,
      isFuzzyMatch: true,
      fuzzyMatchingThreshold: 0.1,
    },
    {
      command: [
        "墙足", "枪足", "强足", "详足", "墙租", "强租", "枪租", 
        "详租", "墙组", "枪组", "强组", "详组", "墙阻", "强阻", "枪阻", 
        "详阻", "强卒", "枪卒", "墙卒", "墙注", "强注", "枪注", "详注", 
        "墙助", "强助", "枪助", "详助"
      ],
      callback: (command: any, transcript: any) => {
        // console.log("???", command);
        console.log("???", command, transcript)
        onFixKeywords(command, '羌族')
      },
      matchInterim: true,
      isFuzzyMatch: true,
      fuzzyMatchingThreshold: 0.1,
    },
    // 其他命令
  ];
}
