import "./App.css";
import React, { useEffect, useState, useRef } from "react";
import AppRouter from './routes/index'
import '../src/language/i18n'
import { ScrollProvider } from "./components/ScrollManager";
import Modal from './components/modal/Modal'
function App() {
  const [openDialog, setOpenDialog] = useState(true);
  return (
    <ScrollProvider>
       <AppRouter />
       <Modal/>
    </ScrollProvider>
  );
}

export default App;
