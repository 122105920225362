const configurations: { [env: string]: any } = {
    test: {
        host: "https://test-meta.tnworld.cn/tn-api/",
        projects: ["qiangxiu", "nuo", "yunjin", "wushi"]
    },
    production: {
        host: "https://meta.tnworld.cn/tn-api/",
        projects: ["qiangxiu", "nuo", "yunjin","wushi"]
    },
}

console.log("11111", process.env.REACT_APP_HOST_TYPE)

export default configurations[process.env.REACT_APP_HOST_TYPE || "test"]