// 音频实时播放
class AudioPlayer {
    mediaSource: MediaSource // 媒体资源
    audio: HTMLAudioElement // 音频元素
    audioContext: AudioContext // 音频上下文
    sourceBuffer?: SourceBuffer // 音频数据缓冲区
    cacheBuffers: ArrayBuffer[] = [] // 音频数据列表
    pauseTimer: number | NodeJS.Timeout| null = null // 暂停定时器
  
    constructor() {
      const AudioContext = window.AudioContext
      this.audioContext = new AudioContext()
  
      this.mediaSource = new MediaSource()
  
      this.audio = new Audio()
      this.audio.src = URL.createObjectURL(this.mediaSource)
  
      this.audioContextConnect()
      this.listenMedisSource()
    }
  
    // 连接音频上下文
    private audioContextConnect() {
      if(!this.audio.src) return
      const source = this.audioContext.createMediaElementSource(this.audio)
      source.connect(this.audioContext.destination)
    }
  
    // 监听媒体资源
    private listenMedisSource() {
      this.mediaSource?.addEventListener('sourceopen', () => {
        if (this.sourceBuffer) return
        this.sourceBuffer = this.mediaSource.addSourceBuffer('audio/mpeg')
  
        this.sourceBuffer?.addEventListener('update', () => {
          if (this.cacheBuffers.length && !this.sourceBuffer?.updating) {
            const cacheBuffer = this.cacheBuffers.shift()!
            this.sourceBuffer?.appendBuffer(cacheBuffer)
          }
  
          this.pauseAudio()
        })
      })
    }
  
    // 暂停音频
    public pauseAudio(force?:any) {
      if(force){
        this.audio.pause();
        this.sourceBuffer = undefined;
        this.cacheBuffers  = [] // 音频数据列表
        this.audio.src = '';
        return
      }
      const neePlayTime = this.sourceBuffer!.timestampOffset - this.audio.currentTime || 0
  
      this.pauseTimer && clearTimeout(this.pauseTimer)
      // 播放完成5秒后还没有新的音频流过来，则暂停音频播放
      this.pauseTimer = setTimeout(() =>{ 
        this.audio.pause();
        window.eventBus.emit('stop-play');
      },  neePlayTime*1000 + 2000 )
    }
  
    private playAudio() {
      // 为防止下一段音频流传输过来时，上一段音频已经播放完毕，造成音频卡顿现象，
      // 这里做了1秒的延时，可根据实际情况修正
      setTimeout(() => {
        // console.log(this.audio, this.audio.paused)
        if (this.audio.paused && this.audio.src) {
          console.log("播放音频")
          try {
            this.audio?.play().catch(e=>{});
          } catch (e) {
            if(this.audio.src){
              this.playAudio();
            }
          }
        }
      }, 1000)
    }
  
    // 接收音频数据
    public receiveAudioData(audioData: ArrayBuffer) {
      if (!audioData.byteLength || !this.audio.src) return
      if (this.sourceBuffer?.updating) {
        this.cacheBuffers.push(audioData)
      } else {
        this.sourceBuffer?.appendBuffer(audioData)
      }
  
      this.playAudio()
    }
  }
  
  export default AudioPlayer
  