import React, { useEffect, useRef, useState } from 'react';
// import { isMobile } from '../../untils/until';
import './transfer.scss';
import Particles from './Particles ';

const Transfer = () => {
  const [progress, setProgress] = useState(0);
  const timer = useRef(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 75) {
          clearInterval(interval);
          return 75;
        }
        return prevProgress + 1;
      });
    }, 150); // 每50毫秒增加1%

    if(!timer.current){
        setTimeout(()=>{
            timer.current= 1;
        },2000)
    }

    // 加载完成后清除定时器 关闭loading
    window.ShowDownLoadPercent = (e: any) => {
      if (e > 0.95) {
        setProgress(Math.ceil(e * 100));
        clearInterval(interval)
      }
      if (e === 1) {
        if(timer.current){
            window.eventBus.emit('loadSuccess');
            window.eventBus.emit('loading-complete');
        }else{
            setTimeout(() => {
                window.eventBus.emit('loadSuccess');
                window.eventBus.emit('loading-complete');
              }, 4000)
        }
      }
    }

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="loading-container1">
        <Particles/>
        <div className='icon-box'>
            <img className='icon-gif' src='https://oss.tnworld.cn/kunlun/assets/loading-logo.gif'></img>
        </div>
    </div>
  );
};

export default Transfer;
