import React, { useState, useEffect } from 'react';
import './partOne.scss';
import ImgDocument from '../../../components/img/ImgDocument';
import { zipImages } from '../../../untils/until';

interface PartOneProps {
  scrollPosition: number; // 新增属性用于接收滚动位置
  mobile: boolean;//是否是移动端
}

const PartOne: React.FC<PartOneProps> = ({ scrollPosition, mobile }) => {
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  // pc端特效图片数组
  const imgArr = [{
    src: 'https://oss.tnworld.cn/kunlun/assets/home/p1a-hill.png',
    top: '70%',
    direction: 'X',
    speed: 0.1,
    zIndex: 14,
    num: 5,
    left: '20%',
    maxScroll: 0.6 * window.innerHeight,
  }, {
    src: 'https://oss.tnworld.cn/kunlun/assets/home/p1a-island.png',
    top: '50%',
    direction: 'Y',
    speed: 0.1,
    zIndex: 14,
    num: 3.5,
    float: 'down',
  },
  {
    src: 'https://oss.tnworld.cn/kunlun/assets/home/p1-island.png',
    top: '0%',
    direction: 'Y',
    speed: 0.1,
    zIndex: 10,
    num: 3.5,
  },
  {
    src: 'https://oss.tnworld.cn/kunlun/assets/home/p1-hill-front.png',
    top: '0%',
    direction: 'Y',
    speed: 0.1,
    zIndex: 6,
    num: 4,
  },
  {
    src: 'https://oss.tnworld.cn/kunlun/assets/home/p1-hill-center.png',
    top: '0%',
    direction: 'Y',
    speed: 0.1,
    zIndex: 5,
    num: 3,
  },
  {
    src: 'https://oss.tnworld.cn/kunlun/assets/home/p1-hill-after.png',
    top: '0%',
    direction: 'Y',
    speed: 0.1,
    zIndex: 4,
    num: 2,
  },
  {
    src: 'https://oss.tnworld.cn/kunlun/assets/home/p1-decoration-text.png',
    top: '0%',
    direction: 'Y',
    speed: 0.1,
    zIndex: 11,
    num: 3.5,
    className: 'breach-light'
  }];

  // 移动端特效图片数组
  const mobileArr = [{
    src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/岛-min.png',
    top: '10%',
    direction: 'Y',
    speed: 0.1,
    zIndex: 12,
    num: 3.5,
    float: 'down',
    maxScroll: 0.6 * window.innerHeight,
  },
  {
    src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/山前-min.png',
    top: '0%',
    direction: 'Y',
    speed: 0.1,
    zIndex: 8,
    num: 3.5,
  },
  {
    src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/山中-min.png',
    top: '0%',
    direction: 'Y',
    speed: 0.1,
    zIndex: 7,
    num: 3.5,
  },
  {
    src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/山后-min.png',
    top: '0%',
    direction: 'Y',
    speed: 0.1,
    zIndex: 6,
    num: 3.5,
  },
  {
    src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/装饰文字-min.png',
    top: '0%',
    direction: 'Y',
    speed: 0.1,
    zIndex: 13,
    num: 3.5,
    className: 'breach-light'
  }];

  return (
    <>
      {/* PC */}
      {!mobile && <div className="part-1" style={{ width: '100vw', position: 'relative' }}>
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/p1-color-block.png")} alt="" style={{ zIndex: 1 }} />
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/p1-dark-corner1.png")} alt="" style={{ zIndex: 2 }} />
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/p1-dark-corner.png")} alt="" style={{ zIndex: 7 }} />
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/p1-excessive-dark-cormer.png")} style={{ zIndex: 8 }} alt="" />
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/p1-logo-cn.png")}
          alt="" style={{ zIndex: 14, animation: 'logo-light 5s ease-in-out infinite' }} />
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/p1-logo-en.png")}
          alt="" style={{ zIndex: 13, animation: 'logo-light 5s ease-in-out infinite' }} />
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/p1-logo-light.png")}
          style={{ zIndex: 7, animation: 'logo-light 5s ease-in-out infinite' }} alt="" />
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/p1-small-decoration.png")} style={{ zIndex: 9 }} alt="" />
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/p1-start.png")} alt="" style={{ zIndex: 12 }} className='breach-star' />
        {
          imgArr.map((item: any, index: any) => {
            return (<ImgDocument key={index} data={item} scrollPosition={scrollPosition} />);
          })
        }
      </div>}
      {/* Mobile */}
      {mobile && <div className="part-1" style={{ width: '100vw', position: 'relative' }}>
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/mobile-home/Logo-min.png")}
          alt="" style={{ zIndex: 15, animation: 'logo-light 5s ease-in-out infinite' }} />
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/mobile-home/星星-min.png")} alt="" style={{ zIndex: 14 }} className='breach-star' />
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/mobile-home/islands-min.png")} style={{ zIndex: 11 }} alt="" />
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/mobile-home/细小光点装饰-min.png")} style={{ zIndex: 11 }} alt="" />
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/mobile-home/过度暗角-min.png")} style={{ zIndex: 10 }} alt="" />
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/mobile-home/暗角-1-min.png")} style={{ zIndex: 9 }} alt="" />
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/mobile-home/Logo发光-min.png")} style={{ zIndex: 5 }} alt="" />
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/mobile-home/暗角-min.png")} style={{ zIndex: 4 }} alt="" />
        <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/mobile-home/色块-min.png")} style={{ zIndex: 3 }} alt="" />
        {
          mobileArr.map((item: any, index: any) => {
            return (<ImgDocument key={index} data={item} scrollPosition={scrollPosition} />);
          })
        }
      </div>}
    </>
  );
};

export default PartOne;
