import React, { useEffect, useState } from 'react';
import { isMobile } from '../../untils/until';
import './loading.scss';

const Loading = () => {
  const [progress, setProgress] = useState(0);
  const [mobile, setMobile] = useState(isMobile());
  const language = localStorage.getItem('language') || 'CN';
  const randomNum = (60 + Math.floor(Math.random() * 20))/100;

  useEffect(() => {
    
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= randomNum*100) {
          clearInterval(interval);
          return randomNum*100;
        }
        return prevProgress + 1;
      });
    }, 300); // 每300毫秒增加1%

    // 加载完成后清除定时器 关闭loading
    window.ShowDownLoadPercent = (e: any) => {
      if (e > randomNum) {
        setProgress(Math.ceil(e * 100));
        clearInterval(interval)
      }
      if (e === 1) {
        setTimeout(() => {
          window.eventBus.emit('loadSuccess');
          window.eventBus.emit('loading-complete');
        }, 3000)
      }
    }

    window.eventBus.on("singleLoadProgress", (e) => {
      if (e > randomNum) {
        setProgress(Math.ceil(e * 100));
        clearInterval(interval)
      }
      if (e === 1) {
        setTimeout(() => {
          console.log("加载成功")
          window.eventBus.emit('loadSuccess');
        }, 200)

        setTimeout(() => {
          console.log("关闭蒙版")
          window.eventBus.emit('loading-complete');
        }, 2000)
      }
    })



    return () => clearInterval(interval);
  }, []);

  return (
    <div className="loading-container">
      { !mobile && 
      <div className="bg11 pc-loading">
        <img className='background-left' src={language === 'CN' ? "https://oss.tnworld.cn/kunlun/assets/cn-loading-guid.png" : "https://oss.tnworld.cn/kunlun/assets/en-loading-guid.png"} alt="" />
        <div className="loadRight">
          <img style={{ width: "70%" }} src='https://oss.tnworld.cn/kunlun/assets/loading-logo.gif'></img>
          <span className="loadText1">{progress}%</span>
          <span className="loadText2">{language === 'CN' ? '场景资源加载中，请稍等' : "Scene content is loading. It won't take long"}</span>
        </div>
      </div>
      }
      { mobile && 
        <div className="mobile-loading" >
          <div className="loading-icon">
            <img className='icon-gif' src='https://oss.tnworld.cn/kunlun/assets/loading-logo.gif'></img>
            <span className="loadText1">{progress}%</span>
            <span className="loadText2">{language === 'CN' ? '场景资源加载中，请稍等' : "Scene content is loading. It won't take long"}</span>
          </div>
          <img className='background-left' src={language === 'CN' ? "https://oss.tnworld.cn/kunlun/assets/mobile-loading-cn.png" : "https://oss.tnworld.cn/kunlun/assets/mobile-loading-en.png"} alt="" />
        </div>
      }
      
    </div>
  );
};

export default Loading;
