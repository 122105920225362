import React, { useState, useEffect } from 'react';
import './partTwo.scss';
import ImgDocument from '../../../components/img/ImgDocument';
import Carousel from '../../../components/carousel/Carousel';
import { zipImages } from '../../../untils/until';
interface PartTwoProps {
    scrollPosition: number; // 新增属性用于接收滚动位置
    mobile: boolean;//是否是移动端
}
const PartTwo: React.FC<PartTwoProps> = ({ scrollPosition, mobile }) => {
    // pc端数组
    const imgArr = [{
        src: 'https://oss.tnworld.cn/kunlun/assets/home/p2-sun.png',
        top: '0%',
        direction: 'X',
        speed: 0.1,
        zIndex: 6,
        num: 5,
        left: '20%',
        maxScroll: window.innerHeight
    }];
    // 移动端数组
    const mobileImgArr = [{
        src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/p2岛.png',
        top: '-50%',
        direction: 'X',
        speed: 0.1,
        zIndex: 6,
        num: 5,
        left: '20%',
        maxScroll: 0.5*window.innerHeight
    },{
        src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/p2太阳.png',
        top: '0%',
        direction: 'X',
        speed: 0.1,
        zIndex: 2,
        num: 2.5,
        left: '40%',
        maxScroll: 1*window.innerHeight
    },{
        src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/p1a山.png',
        top: '-15%',
        direction: 'X',
        speed: 0.1,
        zIndex: 10,
        num: 5,
        left: '20%',
        maxScroll: 0.6*window.innerHeight
    }];
    return (
        <>
            {
                !mobile && <div className="part-2">
                    <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/p2-island.png")} alt="" />
                    <img className='breach-text' src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/p2-text.png")} alt="" />
                    <img className='breach-star' src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/p2-star.png")} alt="" />
                    <Carousel />
                    {
                        imgArr.map((item: any, index: any) => {
                            return (<ImgDocument key={index} data={item} scrollPosition={scrollPosition} />)
                        })
                    }
                </div>
            }
             { 
                mobile && <div className="part-2">
                    <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/mobile-home/p2星星.png")} alt="" style={{zIndex: 13}} />
                    <img className='breach-text' src={zipImages("https://oss.tnworld.cn/kunlun/assets/mobile-home/p2文字.png")} style={{zIndex: 11}}  alt="" />
                    <Carousel />
                    {
                        mobileImgArr.map((item: any, index: any) => {
                            return (<ImgDocument key={index} data={item} scrollPosition={scrollPosition} />)
                        })
                    }
                </div>
            }
        </>

    )
};

export default PartTwo;