import React, { useState, useEffect } from "react";
import './header.scss';
import { Anchor, Dropdown } from "antd";
import { useTranslation } from 'react-i18next';

interface HeaderProps {
    onNavClick: (id: string) => void;
    activeTab: string; // 新增activeTab属性
}

const Header: React.FC<HeaderProps> = ({ onNavClick, activeTab }) => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState('CN');
    const [showAnchors, setShowAnchors] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (localStorage.getItem('language')) {
            changeLanguage(localStorage.getItem('language') || '')
        }
    }, [])

    // 修改语言
    const changeLanguage = (lan: string) => {
        setLanguage(lan);
        localStorage.setItem('language', lan);
        if (lan === 'CN') {
            i18n.changeLanguage('ch');
        } else {
            i18n.changeLanguage('en');
        }
    }

    // 下拉菜单
    const items = [
        {
            key: '1',
            label: (
                <div onClick={() => changeLanguage('CN')}>
                    CN
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div onClick={() => changeLanguage('EN')}>
                    EN
                </div>
            ),
        },
    ];

    const getCurrentAnchor = () => {
        return 'part-2'
    }

    return (
        <header className="navbar">
            <div className="navbar-border">
                <div className="navbar-logo">
                    <img src="https://oss.tnworld.cn/kunlun/assets/kunlun-logo.png" style={{filter: 'invert(1)'}} alt="" />
                </div>
                <nav className="navbar-nav">
                    <Anchor
                        direction="horizontal"
                        items={[
                            { key: 'part-1', href: '#part-1', title: t('home_home') },
                            { key: 'part-2', href: '#part-2', title: t('home_metaverse') },
                            { key: 'part-3', href: '#part-3', title: 'NFT Launchpad' },
                            { key: 'part-4', href: '#part-4', title: t('home_about') }
                        ]}
                        onClick={(e) => {
                            e.preventDefault();
                            const target = e.target as HTMLAnchorElement;
                            const href = target.getAttribute('href');
                            if (href) {
                                const id = href.substring(1);
                                onNavClick(id);
                            }
                        }}
                    />
                </nav>
                <div className="navbar-lang">
                    <Dropdown
                        trigger={['click']}
                        menu={{
                            items,
                        }}
                        placement="bottom"
                        arrow
                    >
                        <div>{language}</div>
                    </Dropdown>
                    <img className="menu" src="/assets/images/menu-line.png" alt="" onClick={() => setShowAnchors(!showAnchors)} />
                    <div className={`${showAnchors ? "anchors-box" : "close-anchors-box"}`}>
                        <Anchor

                            affix={false}
                            items={[
                                { key: 'part-1', href: '#part-1', title: 'Home' },
                                { key: 'part-2', href: '#part-2', title: 'Metaverse' },
                                { key: 'part-3', href: '#part-3', title: 'NFT Launchpad' },
                                { key: 'part-4', href: '#part-4', title: 'About us' }
                            ]}
                            getCurrentAnchor={getCurrentAnchor}
                            onClick={(e) => {
                                e.preventDefault();
                                const target = e.target as HTMLAnchorElement;
                                const href = target.getAttribute('href');
                                if (href) {
                                    const id = href.substring(1);
                                    onNavClick(id);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
