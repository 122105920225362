import React, { useState, useEffect, useRef } from "react";
import { Paper } from "@mui/material";
import TypingMessage from "../../untils/TypingMessage";
import "./speakChat2.scss";
import { useTranslation } from "react-i18next";
import { zipImages } from "../../untils/until";
import { AudioOutlined, PauseOutlined } from "@ant-design/icons";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import VoiceRecognition from "../voiceRecognition/VoiceRecognition";
import config from "../../config";
import AudioPlayer from "./AudioPlayer";

import { Drawer, Radio, Button, Slider } from "antd";
import type { RadioChangeEvent } from "antd";
// import { JSONStringify } from "lib/tool";
import { use } from "i18next";

const SpeakChat = (props: any) => {
  const [open, setOpen] = useState(false);
  const replacements = {
    抢修: "羌绣",
    强休: "羌绣",
    枪袖: "羌绣",
    墙秀: "羌绣",
    强袖: "羌绣",
    枪绣: "羌绣",
    强修: "羌绣",
    墙绣: "羌绣",
    枪秀: "羌绣",
    强秀: "羌绣",
    千秀: "羌绣",
    强舆: "羌绣",
    枪宿: "羌绣",
    墙宿: "羌绣",
    枪修: "羌绣",
    强宿: "羌绣",
    枪绪: "羌绣",
    强绪: "羌绣",
    墙绪: "羌绣",
    枪叙: "羌绣",
    强叙: "羌绣",
    墙叙: "羌绣",
    枪须: "羌绣",
    强须: "羌绣",
    墙须: "羌绣",
    枪絮: "羌绣",
    强絮: "羌绣",
    墙絮: "羌绣",
    枪续: "羌绣",
    强续: "羌绣",
    墙续: "羌绣",
    将臭: "羌绣",
    湘绣: "羌绣",
    墙足: "羌族",
    枪足: "羌族",
    强足: "羌族",
    详足: "羌族",
    墙租: "羌族",
    强租: "羌族",
    枪租: "羌族",
    详租: "羌族",
    墙组: "羌族",
    枪组: "羌族",
    强组: "羌族",
    详组: "羌族",
    墙阻: "羌族",
    强阻: "羌族",
    枪阻: "羌族",
    详阻: "羌族",
    强卒: "羌族",
    枪卒: "羌族",
    墙卒: "羌族",
    墙注: "羌族",
    强注: "羌族",
    枪注: "羌族",
    详注: "羌族",
    墙助: "羌族",
    强助: "羌族",
    枪助: "羌族",
    详助: "羌族",
    // 继续添加更多需要替换的词
  };
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem("language") === "CN" ? "zh-CN" : "en-US");
  const lanCurrent = useRef(localStorage.getItem("language") == "EN" ? false : true);//true是中文
  const [dynamicContent, setDynamicContent] = useState("");
  const [waiting, setWaiting] = useState(false);
  const needWaitingAudio = useRef(true);
  const [messages, setMessages] = useState<any>([]);
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const messageArr = useRef<any>([]);
  const inputRef = useRef<any>(null);
  const { t } = useTranslation();
  const hasDone = useRef(true);
  const [transcribing, setTranscribing] = useState(true);
  const { resetTranscript, transcript, } = useSpeechRecognition({transcribing});
  const [isListening, setIsListening] = useState<boolean>(false);
  const inputVal = useRef(""); //输入框内容
  const isRecover = useRef(false); //是否正在回复
  const canListing = useRef(false); //监听到关键词后 开始监听
  let timer = useRef<any>();
  const audioPlayer = new AudioPlayer();
  const awakeMode = useRef(0)
  const hasListing = useRef(false); //是否已经在监听
  const textArr = useRef<any>([]); //存储所有文字消息
  const [questionArr, setQuestionArr] = useState<any>([
    [
      "What is Qiang embroidery?",
      "The historical background of Qiang embroidery?",
      "The artistic characteristics of Qiang embroidery.",
      "The main techniques of Qiang embroidery.",
    ],
    [
      "The cultural value of Qiang embroidery.",
      "Common patterns and their meanings in Qiang embroidery.",
      "The current state and challenges of Qiang embroidery inheritance.",
      "How can we protect Qiang embroidery?",
    ],
    [
      "The significance of preserving Qiang embroidery.",
      "The representative inheritors of Qiang embroidery techniques",
      "Where is the Qiang ethnic group located?",
      "The customs and culture of the Qiang people.",
    ],
  ]); //FAQ问题数组 英文
  const [questionArrCN, setQuestionArrCN] = useState([
    [
      "什么是羌绣？",
      "羌绣的历史背景。",
      "羌绣的艺术特色。",
      "羌绣的主要技法。",
    ],
    [
      "羌绣的文化价值。",
      "羌绣常见的图案及寓意。",
      "羌绣传承现状和问题。",
      "我们如何保护羌绣？",
    ],
    [
      "传承羌绣的意义。",
      "羌绣技艺代表性传承人。",
      "羌族在哪里？",
      "羌族在哪里？",
    ],
  ]);
  const [questionIndex, setQuestionIndex] = useState(0); //问题轮换索引
  const [isSpeaking, setIsSpeaking] = useState(false);//NPC是否正在说话
  const isRecognizingRef = useRef<boolean>(false);  // 用于跟踪识别状态
  const dbName = 'qiangqiang';
  const storeName = 'chatMessages';
  // 切换语言
  const toggleLanguage = async() => {
    resetartSpeech(false);
    SpeechRecognition.stopListening();
    console.log(language)
    let currentLanguage = language
    setLanguage((prevLanguage) =>
      prevLanguage === "zh-CN" ? "en-US" : "zh-CN"
    );
    lanCurrent.current = !lanCurrent.current;
    let lan = currentLanguage === "zh-CN" ? "EN" : "CN";
    localStorage.setItem("language", lan);
    console.log(lan,'??????????now Language');
    if (lan === "CN") {
      SpeechRecognition.startListening({
        continuous: true,
        language: 'zh-CN',
      }).then(() => {
        console.log('restart success CN');
      }).catch((err) => {
        console.log('restart failed:', err);
      });
      i18n.changeLanguage("ch");
    } else {
      SpeechRecognition.startListening({
        continuous: true,
        language: 'en-US',
      }).then(() => {
        console.log('restart success En');
      }).catch((err) => {
        console.log('restart failed:', err);
      });
      i18n.changeLanguage("en");
    }
    resetartSpeech(true);
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onChange = (e: RadioChangeEvent) => {
    console.log(`radio checked:${e.target.value}`);
    awakeMode.current = e.target.value;
  };

  const clearCache = () => {
    // 打开与 indexedDB 的连接
    let request = indexedDB.deleteDatabase("qiangqiang");

    request.onsuccess = function (event) {
      console.log("数据库 qiangqiang 已成功删除");
      sessionStorage.removeItem("has-open-chat")
      window.location.reload()
    };

    request.onerror = function (event: any) {
      console.error("删除数据库出错:", event.target.error);
    };

    request.onblocked = function (event) {
      console.warn("删除数据库被阻止，请关闭所有其他标签页或窗口后再试。");
    };
  };

  const startWaiting = () => {
    // setDynamicContent("Listening");
    setWaiting(true);
  };

  const handleAwake = () => {
    console.log("!!!!!!!", awakeMode, awakeMode.current === 1)
    if (awakeMode.current === 0) {
      canListing.current = false;
    }
    if (awakeMode.current === 1) {
      startWaiting()
    }
  }

  const handleWaitAudio = () => {
    const audioElement: any = document.getElementById("wait");
    audioElement.play();
  };

  const base64ToArrayBuffer = (base64: string): ArrayBuffer => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  // 替换文字
  const replaceWords = (transcript: any) => {
    let modifiedTranscript = transcript;
    for (const [word, replacement] of Object.entries(replacements)) {
      const regex = new RegExp(word, "g"); // 全局替换
      modifiedTranscript = modifiedTranscript.replace(regex, replacement);
    }
    return modifiedTranscript;
  };

  // 存档聊天
  const saveMessage = () => {
    const request = indexedDB.open(dbName, 3);
    request.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest)?.result;
      const temp1 = messageArr.current.map((item: any, index: any) => {
        return {
          id: index,
          ...item,
          typing: 1
        }
      })
      clearAndAddDataToIndexedDB(db, temp1);
    };
  }
  //发送
  const handleSend = async (question?: any) => {
    // 确保NPC正在回复的时候 无法重复提问
    if (isTyping || (isRecover.current && question)) return;
    setWaiting(false)
    const val = question || replaceWords(inputVal.current);
    resetartSpeech(false);
    setTranscribing(false);
    if (val.trim()) {
      messageArr.current = [
        ...messageArr.current,
        { text: val, sender: "user", typing: 1 },
        { typing: 3, text: 1, sender: "bot" },
      ];
      setMessages((prevMessages: any) => {
        return [
          ...prevMessages,
          { text: val, sender: "user", typing: 1 },
          { typing: 3, text: 1, sender: "bot" },
        ];
      });
      setTimeout(() => {
        scrollToBottom();
      }, 100);

      setIsTyping(true);

      const lan = lanCurrent.current ? "cn" : "en";
      const host = config.host;

      let eventSource: any = new EventSource(
        `${host}qwenStreamVoice?userMessage=` + val + "&lan=" + lan
      );
      setIsSpeaking(true);
      isRecover.current = true;
      resetTranscript();
      setInput("");
      inputVal.current = "";
      let messageStr = "";
      const length = messageArr.current.length - 1;
      eventSource.onmessage = function (event: any) {

        if (event.data === "emitter end") {
          hasDone.current = true;
          eventSource.close();
          return;
        }

        const data = JSON.parse(event.data);

        if (data?.voiceMessage) {
          const base64AudioData = data?.voiceMessage;
          const arrayBuffer = base64ToArrayBuffer(base64AudioData);
          audioPlayer.receiveAudioData(arrayBuffer);
          setTimeout(() => {
            messageArr.current = textArr.current;
            setMessages(textArr.current);
            // 存档
            saveMessage();
          }, 50);
        } else {
          const text = data.output.choices[0].message.content;
          messageStr += text;
          const temp = JSON.parse(JSON.stringify([...messageArr.current]));
          temp[length] = { text: messageStr, sender: "bot", typing: 2 };
          textArr.current = JSON.parse(JSON.stringify(temp));
        }
      };
    }
  };

  // FAQ问题点击切换
  const nextQuestion = () => {
    setQuestionIndex((prevIndex) => (prevIndex + 1) % questionArr.length);
  };

  // 分贝低于临界值 结束文本转换
  const endListing = () => {
    // 给用户看的 结束说话的标识
    setIsListening(false);
    // 设置定时器 短时间内只能触发一次发送指令
    console.log('??????????abc', timer.current, inputVal.current)
    if (!timer.current && (inputVal.current || input)) {

      // 关闭唤醒
      isRecover.current = true;
      timer.current = setTimeout(() => {
        console.log("clear timer");
      }, 200);
      setTimeout(() => {
        console.log('???????????????send')
        // 等待异步赋值后 执行发送
        window.eventBus.emit("send-message");
        clearTimeout(timer.current);
        timer.current = null;
      }, 200);
    }
  };

  // 注册或卸载语音识别异常中断监听
  const resetartSpeech = (flag: boolean) => {
    // 在每次打开后开始异常中断监听与重启 在主动结束识别时取消监听
    const recognition = (SpeechRecognition as any).getRecognition();
    if (flag) {
      const handleRecognitionEnd = () => {
        console.log("Recognition ended, restarting...");
        SpeechRecognition.startListening({
          continuous: true,
          language: lanCurrent.current?'zh-CN':'en-US',
        }).then(() => {
          console.log('restart success',lanCurrent.current);
        }).catch((err) => {
          console.log('restart failed:', err);
        });
      };
      recognition.onend = handleRecognitionEnd;
    } else {
      recognition.onend = null;
    }
  };

  useEffect(() => {
    setTranscribing(true);
    SpeechRecognition.startListening({continuous: true, language: lanCurrent.current?'zh-CN':'en-US' });
    resetartSpeech(true);

    setInterval(() => {
      console.log(transcript)
    }, 1000)

    return () => {
      SpeechRecognition.stopListening();
    }
  }, []);
  // 清空并添加数据
  const clearAndAddDataToIndexedDB = (db: IDBDatabase, dataArray: any[]) => {
    const transaction = db.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);

    // 清空存储对象
    const clearRequest = objectStore.clear();

    clearRequest.onsuccess = () => {
      // 添加新数据
      dataArray.forEach((data) => {
        const addRequest = objectStore.add(data);
        addRequest.onerror = (event) => {
          console.error('Error adding data:', (event.target as IDBRequest).error);
        };
      });
    };

    clearRequest.onerror = (event) => {
      console.error('Error clearing object store:', (event.target as IDBRequest).error);
    };
  };

  // 读取数据
  const readDataFromIndexedDB = (db: IDBDatabase) => {
    const transaction = db.transaction([storeName], 'readonly');
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.getAll();
    console.log('??????abcabcabcabcabcabcabc')
    request.onsuccess = (event) => {
      if ((event.target as IDBRequest).result?.length) {
        setMessages((event.target as IDBRequest).result);
        messageArr.current = (event.target as IDBRequest).result;
      }
    };

    request.onerror = (event) => {
      console.error('Error reading data:', (event.target as IDBRequest).error);
    };
  };

  useEffect(() => {
    // 用户结束发言
    window.eventBus.on("end-speak", () => {
      endListing();
    });
    // NPC的语音回复停止
    window.eventBus.on("stop-play", () => {
      resetTranscript();
      // 关闭唤醒
      handleAwake()
      setIsSpeaking(false);
      console.log("stop-play");
      isRecover.current = false;
      needWaitingAudio.current = true;
      SpeechRecognition.startListening({
        continuous: true,
        language: lanCurrent.current?'zh-CN':'en-US',
      }).then(() => {
        console.log('restart success',lanCurrent.current);
      }).catch((err) => {
        console.log('restart failed:', err);
      });
      resetartSpeech(true);
    });

    // 默认显示欢迎语 判断是不是第一次打开
    const str = t("scene_1_npc_desc").split("\n")[0];
    const str1 = t("scene_1_npc_desc").split("\n")[1];
    const str2 = t("scene_1_npc_desc").split("\n")[2];
    if (sessionStorage.getItem("has-open-chat")) {
      let temp: any = [
        {
          text: str,
          sender: "bot",
          typing: 1,
        },
        {
          text: str1,
          sender: "bot",
          typing: 1,
        },
        {
          text: str2,
          sender: "bot",
          typing: 1,
        },
      ];
      console.log('??????abcabcabc1')
      setMessages(temp);
      messageArr.current = temp;
      const request = indexedDB.open(dbName, 3);
      request.onsuccess = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        // 读取数据
        readDataFromIndexedDB(db)
      };
      request.onerror = (event) => {
        console.error('Error clearing object store:', (event.target as IDBRequest).error);
      };
    } else {
      let obj = {};
      obj = {
        text: str,
        sender: "bot",
        typing: 2,
      };
      let temp: any = [];
      temp[0] = obj;
      messageArr.current = temp;
      setTimeout(() => {
        obj = {
          text: str1,
          sender: "bot",
          typing: 2,
        };
        temp.push(obj);
        console.log('??????abcabcabc2')
        setMessages(temp);
        messageArr.current = temp;

        setTimeout(() => {
          obj = {
            text: str2,
            sender: "bot",
            typing: 2,
          };
          temp.push(obj);
          console.log('??????abcabcabc3')
          setMessages(temp);
          messageArr.current = temp;
          const request = indexedDB.open(dbName, 3);
          request.onupgradeneeded = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            if (!db.objectStoreNames.contains(storeName)) {
              db.createObjectStore(storeName, { keyPath: 'id', autoIncrement: true });
              console.log(`Object store "${storeName}" created.`);
            }
          };
          sessionStorage.setItem("has-open-chat", "true");
        }, 2100);
      }, 3200);
    }
    return () => {
      window.eventBus.off("end-speak", () => {
        endListing();
      });
      window.eventBus.off("stop-play", () => (isRecover.current = false));
    };
  }, []);

  // 监听文本变化
  useEffect(() => {
    console.log("transcript???????????", transcript);
    if (canListing.current && !isRecover.current) {
      setInput(transcript);
      inputVal.current = transcript;
    }
    // 文本不为空 且 NPC没有在说话的时候
    if (transcript.trim() && !canListing.current) {
      // 第一次唤醒 先把前面的文本清空 然后开始文本记录
      // 文本里面带有 嗨  你好 hi的时候进行唤醒操作
      if (
        (!canListing.current &&
          (transcript.includes("嗨") ||
            transcript.includes("你好") ||
            transcript.includes("您好") ||
            transcript.includes("Hi"))) ||
        transcript.includes("hi")
      ) {
        console.log("start ", transcript, canListing.current);
        setTimeout(() => {
          console.log(needWaitingAudio.current)
          if (needWaitingAudio.current) {
            handleWaitAudio();
          }
          startWaiting()
          setTimeout(() => {
            needWaitingAudio.current = false
            // 第一次唤醒的标识
            canListing.current = true;
            // 清空文本
            resetTranscript();
            // 给用户看的 说话中的标识
            setIsListening(true);
          }, 1500)

        }, 1000);
      } else if (transcript?.length > 10) {
        resetTranscript();
      }
    }
  }, [transcript]);

  // 监听输入框值的实时变化
  useEffect(() => {
    let timerTemp: any = null;
    // 当两个值相等后即异步完成 并且发送消息 为了只注册一次 设置hasListing作为监听标识
    if (!hasListing.current && input) {
      hasListing.current = true;
      window.eventBus.on("send-message", () => {
        // 防抖
        if (!timerTemp) {
          timerTemp = setTimeout(() => {
            handleSend();
            clearTimeout(timerTemp);
          }, 200);
        } else {
          clearTimeout(timerTemp);
          timerTemp = setTimeout(() => {
            handleSend();
            clearTimeout(timerTemp);
          }, 200);
        }
      });
    }
  }, [input]);

  // 停止NPC回复
  const stopRecover = (index: any) => {
    setIsSpeaking(false);
    window.eventBus.emit("stop-source");
    hasDone.current = true;
    handleTypingComplete(index);
  }

  const scrollToBottom = () => {
    var bottomElement: any = document.getElementById("chat-box");
    bottomElement.scrollTop = bottomElement.scrollHeight;
  };

  // 关闭弹窗
  const closeModal = () => {
    try {
      const video: any = document.getElementById('myVideo');
      video.muted = true; // 确保视频是静音的，以满足自动播放要求
      video.play();
    } catch (err) {
    }
  };

  const handleTypingComplete = (index: any) => {
    if (!hasDone.current) return;

    setIsTyping(false);
    console.log('??????abcabcabc4')
    setMessages((prevMessages: any) => {
      const updatedMessages = [...prevMessages];
      updatedMessages[index].typing = 1;
      messageArr.current = updatedMessages;
      return updatedMessages;
    });
  };

  return (
    <div className="chat-dialog1" onClick={closeModal}>
      <video autoPlay={true} muted loop id="myVideo">
        <source src="https://tnworld.oss-cn-shanghai.aliyuncs.com/kunlun/assets/qiang-audio.mp4" type="video/mp4" />
      </video>
      <div className="bg-cover"></div>
      <Drawer
        title="设置"
        placement="top"
        width={500}
        onClose={onClose}
        open={open}
      >
        <div style={{ marginBottom: "20px" }}>唤醒模式</div>
        <Radio.Group onChange={onChange} defaultValue={0} block>
          <Radio.Button value={0}>你好羌羌</Radio.Button>
          <Radio.Button value={1}>自动</Radio.Button>
          <Radio.Button value={2} disabled>
            手动
          </Radio.Button>
        </Radio.Group>

        <div style={{ margin: "20px 0" }}>清空缓存</div>
        <Button type="primary" onClick={clearCache}>清空缓存并刷新</Button>
      </Drawer>
      <div className="close-box" onClick={showDrawer}>
        <img src="/assets/images/menu-line.png" alt="" />
      </div>
      <div
        className="close-box"
        style={{ right: "60px" }}
        onClick={toggleLanguage}
      >
        {language === "zh-CN" ? "中" : "EN"}
      </div>

      <div className="dynamic-island">{dynamicContent}</div>

      <audio
        id="wait"
        src={
          language === "zh-CN"
            ? "/assets/loongstella_cn.mp3"
            : "/assets/loongstella_en.mp3"
        }
      ></audio>

      {waiting && (
        <div className="wait">
          <div className="boxContainer">
            <div className="box box1"></div>
            <div className="box box2"></div>
            <div className="box box3"></div>
            <div className="box box4"></div>
            <div className="box box5"></div>
          </div>

          <div className="send-btn" onClick={() => { handleSend() }}>SEND</div>
        </div>
      )}

      <div className="chat-container1" onClick={(e) => e.stopPropagation()}>
        <div className="chat-content1"></div>
        <div className="virtual">
          <img
            src={zipImages(
              "https://oss.tnworld.cn/kunlun/assets/xiu/xiuxiu.png"
            )}
            alt=""
          />
        </div>

        <div className="messages" id="chat-box">
          {messages.map((message: any, index: any) => (
            <div key={index} className={`message ${message.sender}`}>
              <Paper className="message-text">
                {message.typing == 2 ? (
                  <TypingMessage
                    message={message.text}
                    answering={() => setIsTyping(true)}
                    onComplete={() => handleTypingComplete(index)}
                    isTyping={isTyping}
                  />
                ) : message.typing == 1 ? (
                  message.text
                ) : (
                  <>
                    <span className="dot">.</span>
                    <span className="dot">.</span>
                    <span className="dot">.</span>
                  </>
                )}
              </Paper>
              {/* {(index == messages.length - 1 && isSpeaking) && <div className="stop-recover-box" onClick={()=>stopRecover(index)}>
                      <img src="/assets/images/stop-circle.png" alt="" />
                      <p>Stop Generating</p>
              </div>} */}
            </div>
          ))}
        </div>
        <div className="audio-chat-bottom-box">
          <div className="q-and-a-box">
            <div className="q-a-title">
              <p>FAQ</p>
              <img
                src="/assets/images/shuffle-line.png"
                alt=""
                onClick={nextQuestion}
              />
            </div>
            {language !== "en-US" &&
              questionArrCN[questionIndex].map(
                (element: string, index: number) => (
                  <div
                    className="q-a-text"
                    key={index}
                    onClick={() => handleSend(element)}
                  >
                    {element}
                  </div>
                )
              )}
            {language === "en-US" &&
              questionArr[questionIndex].map(
                (element: string, index: number) => (
                  <div
                    className="q-a-text"
                    key={index}
                    onClick={() => handleSend(element)}
                  >
                    {element}
                  </div>
                )
              )}
            <div className="parting-line"></div>
            <div className="input-container">
              <textarea
                ref={inputRef}
                autoFocus={false}
                value={input}
                onFocus={scrollToBottom}
                onInput={(e: any) => {
                  setInput(e.target.value);
                  inputVal.current = e.target.value;
                }}
                onKeyDown={(e) => {
                  e.key === "Enter" && handleSend();
                }}
                placeholder={
                  isListening
                    ? i18n.t("chat_listening")
                    : i18n.t("chat_start_tip")
                }
                autoComplete="off"
              />
              {/* {!isListening && <AudioOutlined />}
              {isListening && <PauseOutlined onClick={endListing} />} */}
              <img
                src="/assets/images/arrow-back.png"
                onClick={handleSend}
                className="arrow-back1"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {!isSpeaking && <VoiceRecognition></VoiceRecognition>}
    </div>
  );
};

export default SpeakChat;
